import React, { useEffect, useState } from "react";
import FixedLayout from "../Layout/FixedLayout";
import Bottombar from "../Appbar/Bottombar";
import { Button, Drawer, Grid, InputAdornment, Paper, TextField } from "@mui/material";
import NewUserCard from "./NewUserCard";
import {
  createNewUser,
  deleteTeamMember,
  getTeamUsers,
  handleEBoxModal,
} from "../../API/Reducer/userReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./NewUserList.scss";
import settings from "../../MIS/Settings.json";
import { useForm } from "react-hook-form";
import { formatUTCDate, handleGetLocalStorage, handleMaxLengthInput } from "../../MIS/Global";
import AlertComp from "../AlertMessageCom/AlertComp";
import SearchIcon from "@mui/icons-material/Search";

const NewUserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userStatusData, userTeamList } = useSelector((state) => state.user);

  const [menudrawer, setMenuDrawer] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      uname: "",
      umob: "",
      paswd: "",
      uemail: "",
      cmpid: userStatusData?.cmpid,
      urole: 1,
      regdate: formatUTCDate(new Date(), "ymd"),
      expdate: formatUTCDate(new Date(userStatusData?.expdate), "ymd"),
      task: settings?.taskSetting,
    },
  });

  const onSubmit = (data) => {
    data = {
      ...data,
      cmpid: userStatusData?.cmpid,
      expdate: formatUTCDate(new Date(userStatusData?.expdate), "ymd"),
      paswd: data?.umob,
    };
    dispatch(createNewUser(data, true, reset, setMenuDrawer));
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  useEffect(() => {
    let data={
      cmpid:handleGetLocalStorage('cmpid'),
      urole:1
    }
    dispatch(getTeamUsers(data));
  }, []);

  const filteredBillData = userTeamList.filter((element) => {
    const searchStr = searchInput.toLowerCase();
    return (
      String(element.uname).toLowerCase().includes(searchStr) ||
      String(element.umob).toLowerCase().includes(searchStr) ||
      String(element.uemail).toLowerCase().includes(searchStr)
    );
  });
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

 

  const headerContent = (
    <Grid container spacing={1} p={1}>
      <Grid
        item
        xs={1}
        className="bckarwcon"
        onClick={(e) => {
          navigate("/home");
        }}
      >
        <ArrowBackIcon className="bckarow" />
      </Grid>
      <Grid item xs={3} className="hading">
        Team
      </Grid>
      <Grid item xs={8} className="btncon">
        <Button
          color="primary"
          type="button"
          size="small"
          variant="contained"
          onClick={(e) => {
            if (userStatusData?.ustatus == 1 || userStatusData?.ustatus == 2) {
              dispatch(handleEBoxModal());
              return;
            }
            setMenuDrawer(!menudrawer);
          }}
        >
          New
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ paddingBottom: "5px", display: "flex", justifyContent: "end" }}
      >
        <TextField
          label="Search"
          size="small"
          variant="standard"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="start"             
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchInputChange}
          value={searchInput}
        />
      </Grid>
    </Grid>
  );
  return (
    <>
      <AlertComp />
      <FixedLayout header={headerContent} footer={<Bottombar/>}>
        <Grid container spacing={2}>
          {filteredBillData.map((element, index) => {
            let bgcolor = "#fff";
            if (index % 2 == 0) {
              bgcolor = "aliceblue";
            }
            return (
              <Grid item xs={12} m={1}>
              <Paper
                key={index}
                elevation={2}
                sx={{ backgroundColor: bgcolor, padding:"10px" }}                
              >              
                  <NewUserCard element={element}/>
              </Paper>
                </Grid>
            );
          })}
        </Grid>
      </FixedLayout>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            // height: "80vh",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            // padding: "15px",
          },
        }}
        open={menudrawer}
        anchor="bottom"
        onClose={(e) => {
          setMenuDrawer(!menudrawer);
        }}
      >
        
        <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
          <Grid container spacing={2} p={1.5}>
            <Grid
              item
              xs={12}
              ms={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>New User</h3>
              <div
                onClick={() => {
                  setMenuDrawer(!menudrawer);
                }}
                className="close"
              >
                &times;
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                label="User Name"
                variant="outlined"
                inputProps={{
                  onInput: (event) => handleMaxLengthInput(event, 100),
                }}
                {...register("uname", {
                  required: "User Namne is required",
                  minLength: {
                    value: 3,
                    message: "User Name must be at least 3 character long",
                  },
                })}
                error={!!errors.uname}
                helperText={errors.uname?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                type="number"
                size="small"
                fullWidth
                label="Mobile Number"
                variant="outlined"
                inputProps={{
                  onInput: (event) => handleMaxLengthInput(event, 10),
                }}
                {...register("umob", {
                  required: "Mobile Number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile Number must be at least 10 digit",
                  },
                })}
                error={!!errors.umob}
                helperText={errors.umob?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                type="text"
                size="small"
                fullWidth
                label="Email"
                variant="outlined"
                inputProps={{
                  onInput: (event) => handleMaxLengthInput(event, 100),
                }}
                {...register("uemail", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Enter a valid email address",
                  },
                })}
                error={!!errors.uemail}
                helperText={errors.uemail?.message}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button type="submit" variant="contained" color="success">
                Save
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{textAlign:"center",color:"#FF0000"}}
            >
              <h5>* The default password for a new user is their registered mobile number. </h5>
            </Grid>
          </Grid>
        </form>
      </Drawer>
    </>
  );
};

export default NewUserList;
