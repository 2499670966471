import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { AppBar, Toolbar, Container, Box, CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingCall, getSettingList, readSettingDataFObject } from '../../API/Reducer/settingReducer';
import { handlePendingDaysCall, updatePendingTask } from '../../API/Reducer/taskReducer';
import EBBox from '../GlobalComponent/EBBox';
import { handleGetLocalStorage } from '../../MIS/Global';
 

const FixedLayout = ({ header, footer, children,color="#fff" }) => {
  const dispatch = useDispatch();
  const {settingCall,settings} = useSelector((state) => state.setting);
  const {pendingDaysCall} = useSelector((state) => state.task);
  useEffect(() => {
    let id=handleGetLocalStorage('id');
    if (id==undefined || id==null) {
      window.location.href="/login";
    }
  }, []);
  useEffect(() => {
    if(settingCall===false){
      dispatch(getSettingList());
      dispatch(getSettingCall());  
    }
  },[settingCall])


  useEffect(() => {   
    if(pendingDaysCall===false && Object.keys(settings).length  > 0){
      dispatch(updatePendingTask(
        {mode:1,pdays:readSettingDataFObject(settings,"task","pendingdays")}
      ));
      dispatch(handlePendingDaysCall());
    }
  },[pendingDaysCall,settings])

  const handleRefresh = () => {
    return new Promise((resolve) => {
      window.location.reload(); // Reload the page
      resolve(); // Resolve the promise after reload (optional)
    });
  };
  return (
    <>
     <EBBox/>
     {/* <PullToRefresh
      onRefresh={handleRefresh}
     > */}
      {/* <CssBaseline /> */}
      <AppBar position="sticky" color='default'  sx={{p:0,m:0,b:0,backgroundColor:"#FFF"}}>
        {/* <Toolbar sx={{p:0,m:0,b:0}}> */}
          {header}
        {/* </Toolbar> */}
      </AppBar>
      {/* <Toolbar /> Spacer for the header */}
     
      <Box 
      sx={{ flex: 1, py: { xs: 4, sm: 4, md: 4 },m:0,
       mb: 8,backgroundColor:`${color}`,overflow:"hidden"}}>
        {children}
      </Box>
      
      <Box
        component="footer"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          bgcolor: 'background.paper',
        }}
      >
        {footer}
      </Box>
      {/* </PullToRefresh> */}
    </>
  );
};

export default FixedLayout;
