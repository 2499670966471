import React from 'react';
import { Box, Typography } from '@mui/material';

const BorderedDivWithLabel = ({ label, children }) => {
  return (
    <Box
      sx={{
        border: '1px solid black',
        borderRadius: '4px',
        padding: '16px',
        position: 'relative',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'self-start',
        width:'100%'
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: '-10px',
          padding: '0 8px',
          backgroundColor: 'white',
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default BorderedDivWithLabel;
