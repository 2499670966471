import { createSlice } from "@reduxjs/toolkit";
import { setConfirmDialogData } from "./confirmDialogReducer";
import {
  callAjax,
  formatDatetime_DMY,
  formatDatetime_YMD,
  getImage,
  handleAdminSetLocalStorage,
  handleLocalStorage,
} from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";

const userReducer = createSlice({
  name: "user",
  initialState: {
    user: {},
    userData: [],
    userCounts: [],
    ebBox:false,
    userStatusData:[],
    ebBoxModal:false,
    userTeamList:[],
  },

  reducers: {
    setUser(state, action) {
      state.user = {
        ...state.user,
        [action.payload.name]: action.payload.value,
      };
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setUserCount(state, action) {
      state.userCounts = action.payload;
    },
    setEBBox(state, action) {
      state.ebBox = action.payload;
    },
    setUserStatusData(state,action){
      state.userStatusData=action.payload;
    },
    setEboxModal(state,action){
      state.ebBoxModal=action.payload;
    },
    setUserTeamList(state,action){
      state.userTeamList=action.payload;
    },
  },
});

export const { setUser, setUserData, setUserCount,setEBBox,setUserStatusData,setEboxModal,setUserTeamList} = userReducer.actions;
export default userReducer.reducer;

export const handleUserChange = (e, name) => {
  return function actionData(dispatch) {
    if (e && e.target) {
      dispatch(setUser({ name: e.target.name, value: e.target.value }));
    }
  };
};

export const otpEmail = (data, otp, setActiveStep) => {
  let isError = 0;
  return async function actionData(dispatch) {
    data = {
      email: data?.uemail,
      title: "OTP",
      subject: "D2 Super",
      message: otp,
      imgurl: getImage("public/d2/d2logo.png"),
      umob: data?.umob,
    };
    let res = await callAjax("otpemail", data);
    if (res?.data > 0) {
    } else if (res?.data <= -1) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `The user already exists with the phone number ${data?.umob}.`,
          code: 100,
        })
      );
      setActiveStep(1);
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
      setActiveStep(1);
    }
  };
};

export const forgotOtpEmail = (data, otp, setActiveStep, setValue) => {
  let isError = 0;
  return async function actionData(dispatch) {
    data = {
      title: "OTP",
      subject: "D2 Super",
      message: otp,
      imgurl: getImage("public/d2/d2logo.png"),
      umob: data?.umob,
    };
    let res = await callAjax("forgototp", data);
    if (res?.data > 0) {
      setValue("id", res?.data);
    } else if (res?.data <= -1) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `This mobile number is not registered.`,
          code: 100,
        })
      );
      setActiveStep(0);
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
      setActiveStep(0);
    }
  };
};

export const saveUser = (data) => {
  let isError = 0;
  return async function actionData(dispatch) {
    data = { ...data, cmpmob: data?.umob };
    let url = "signup";
    if (data?.id > 0) {
      url = "userupdate";
    }
    let res = await callAjax(url, data);
    if (res?.data > 0) {
      if (url === "userupdate") {
        dispatch(
          setAlertData({
            type: "success",
            msg: `Your account has been updated successfully`,
            code: 200,
          })
        );
      } else {
        dispatch(
          setAlertData({
            type: "success",
            msg: `Great! Your account has been created successfully. Let's get started!`,
            code: 200,
          })
        );
      }
      reloadPage(500, "/login");
    } else if (res?.data <= -1) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `User already exists.`,
          code: 100,
        })
      );
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};

export const signUser = (data) => {
  return async function actionData(dispatch) {
    let res = await callAjax("signin", data);    
    if (res?.data?.id > 0) {
      dispatch(
        setAlertData({
          type: "success",
          msg: `Sign in successfully.`,
          code: 200,
        })
      );
      handleLocalStorage(res?.data);
      reloadPage(500, "/home");
    } else if (res?.data == -1) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `Invalid Credentials (Mobile Number & Password).`,
          code: 100,
        })
      );
    } else if (res?.data == -2) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `Your account has been blocked due to security reasons. Please contact the administrator to resolve this issue and regain access.`,
          code: 100,
          duration: 5000,
        })
      );
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};

export const getUsers = (data) => {
  let isError = 0;
  return async function actionData(dispatch) {
    let res = await callAjax("getusers", data);
    if (res?.data?.length > 0) {
      dispatch(setUserData(res?.data));
    } else {
      dispatch(setUserData([]));
    }
  };
};

export const getUsersCounts = (data) => {
  let isError = 0;
  return async function actionData(dispatch) {
    let res = await callAjax("getusercount", data);
    if (res?.data?.length > 0) {
      dispatch(setUserCount(res?.data));
    } else {
      dispatch(setUserCount([]));
    }
  };
};

export const saveUserStatus = (data) => {
  let isError = 0;
  return async function actionData(dispatch) {
    data = { ...data, cmpmob: data?.umob };
    let url = "userupdate";
    if (data?.id > 0) {
      url = "userupdate";
    }
    let res = await callAjax(url, data);
    if (res?.data > 0) {
      if (url === "userupdate") {
        dispatch(
          setAlertData({
            type: "success",
            msg: `Status Updated Successfully`,
            code: 200,
          })
        );

        dispatch(getUsersCounts());
      } else {
        dispatch(
          setAlertData({
            type: "success",
            msg: `Status Updated Successfully!`,
            code: 200,
          })
        );
      }
    } else if (res?.data <= -1) {
      dispatch(
        setAlertData({
          type: "error",
          msg: `User already exists.`,
          code: 100,
        })
      );
    } else {
      dispatch(
        setAlertData({
          type: "error",
          msg: "Something went wrong!Please try again",
          code: 100,
        })
      );
    }
  };
};

export const handleEBBox = (data) => {
  let isError = 0;
  return async function actionData(dispatch,getState) {
     const {ebBox}=getState().user;
     dispatch(setEBBox(!ebBox));
  };
};

export const handleEBoxModal = (data) => {
  let isError = 0;
  return async function actionData(dispatch,getState) {
     const {ebBoxModal}=getState().user;
     dispatch(setEboxModal(!ebBoxModal));
  };
};

export const getUsersStatus = (data,bal=true) => {
   return async function actionData(dispatch) {
    let res = await callAjax("userstatus",data,bal);
    if (res?.data) {
      dispatch(setUserStatusData(res?.data));
    } else {
      dispatch(setUserStatusData([]));
    }
  };
};

export const createNewUser = (data,bal=true,reset,setMenuDrawer) => {
  return async function actionData(dispatch,getState) {
    const {userTeamList}=getState().user;
   let res = await callAjax("create_user",data,bal);
   if (res?.data > 0) {
    dispatch(
      setAlertData({
        type: "success",
        msg: `Great! Team member has been created successfully.`,
        code: 200,
      })
    );
    const newArray = [...userTeamList];
    newArray.unshift(data);
    dispatch(setUserTeamList(newArray));
    reset();
    setMenuDrawer(false);
   }else if(res?.data <= -1){
    dispatch(setAlertData({
      type: "error",
      msg: "User already exist!",
      code: 100,
    }))
   } 
   else {
    dispatch(
      setAlertData({
        type: "error",
        msg: "Something went wrong!Please try again",
        code: 100,
      })
    );
   }
 };
};


export const getTeamUsers = (data) => {
  return async function actionData(dispatch) {
    let res = await callAjax("getusers", data);
    if (res?.data?.length > 0) {
      dispatch(setUserTeamList(res?.data));
    } else {
      dispatch(setUserTeamList([]));
    }
  };
};

export const deleteTeamMember = (data) => {   
  return async function actionData(dispatch, getState) {
    const {userTeamList}=getState().user;
      let url="teamuserdelete";       
      let res = await callAjax(url, data);
      if (res.data > 0) {
        const updatedItems = userTeamList.filter(item => item.umob !== data?.umob);
        dispatch(setUserTeamList(updatedItems));
        dispatch(
          setAlertData({
            type: "success",
            msg: "Team member deleted successfully",
            code: 200,
          })
        );        

      } else {
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
      }
    };
  };


  export const adminLogin = (data) => {       
    return async function actionData(dispatch, getState) {
       const upData=[
        {
          umob:'9822299281',
          paswd:'9822299281',
        },
        {
          umob:'9960008313',
          paswd:'admin@123!',
        }
       ]
       let res=upData.find(user => user.umob === data.umob && user.paswd === data.paswd) !== undefined;       
        if (res) {         
          dispatch(
            setAlertData({
              type: "success",
              msg: "Login successfully",
              code: 200,
            })
          );        
          handleAdminSetLocalStorage({id:1});
           setTimeout(() => {
            window.location.href="/admin/userlist";
           }, 500); 
        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Invalid credential! Please try again",
              code: 100,
            })
          );
        }
      };
    };