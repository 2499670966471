import React from "react";
import Grid from "@mui/material/Grid";
import style from "./productlist.module.css";
import {
  Fab,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material/styles";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  ml: 1,
  flex: 1,
  color: "#fff",
  borderBottom: "solid 1px #fff",
  width: "100%",
  "&amp; .MuiInputBase-input::placeholder": {
    color: alpha(theme.palette.common.black, 1), // Change this to your desired color
  },
}));
const ProductList = () => {
  const myArray = Array.from({ length: 10 }, (_, index) => index);
  return (
    <>
      <Grid container spacing={2} sx={{ height: "100vh" }}>
        <Grid
          item
          container
          md={12}
          xs={12}
          sx={{
            height: "10%",
            alignItems: "center",
            backgroundColor: "#9ac1f0",
            justifyContent: "center",
          }}
        >
          <Grid item xs={2} md={6} pl={1} className={style?.pageheading}>
            Items
          </Grid>
          <Grid
            item
            pl={3}
            xs={8}
            md={6}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledInputBase
              placeholder="Search Items"
              inputProps={{ "aria-label": "search items" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            pr={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <FilterAltIcon sx={{ color: "#fff" }} />
          </Grid>
        </Grid>
        <Grid
          item
          container
          md={12}
          xs={12}
          sx={{
            height: "75%",
            backgroundColor: "aliceblue",
            overflowY: "scroll",
          }}
        >
          {myArray.map((item, index) => {
            let bgcolor = "#deebfa";
            if (index % 2 == 0) {
              bgcolor = "#fff";
            }
            return (
              <>
                <Grid item xs={12} md={12} p={1}>
                  <Paper
                    elevation={2}
                    sx={{ padding: "10px", backgroundColor: bgcolor }}
                  >
                    <div className={style.pcontainer}>
                      <div className={style.pc1}>
                        <div className={style.pc2}>
                          <span className={style.itemname}>
                            Item Name {index}
                          </span>
                          <span className={style.catgname}>Category Name</span>
                        </div>
                        <div className={style.pc2}>
                          <span className={style.itemname}>0</span>
                          <span className={style.catgname}>Unit Name</span>
                        </div>
                      </div>
                      <div className={style.pc1}>
                        <div className={style?.pc2}>
                          <span className={style.priceheading}>
                            Sales Price
                          </span>
                          <span className={style.price}>&#x20B9;0</span>
                        </div>
                        <div className={style?.pc2}>
                          <span className={style.priceheading}>
                            Purchase Price
                          </span>
                          <span className={style.price}>&#x20B9;0</span>
                        </div>
                        <div className={style?.pc2}>
                          <span className={style.catgname}>Category Name</span>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </>
            );
          })}
        </Grid>
        <Grid
          item
          container
          md={12}
          xs={12}
          sx={{
            height: "8%",
            alignItems: "center",
            justifyContent: "center",
            alignItems: "center",
            position:"sticky",
            zIndex:"9"
          }}
        >
          <Fab
            variant="extended"
            size="small"
            sx={{
              padding: "15px",
              backgroundColor: "#72fa93",
              color: "#000",
            }}
          >
            <AddCircleOutlineIcon />
            &nbsp;&nbsp;
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "capitalize",
              }}
            >
              Create New Item
            </span>
          </Fab>
        </Grid>
        <Grid
          item
          container
          md={12}
          xs={12}
          sx={{ height: "7%", alignItems: "center" }}
        >
          
        </Grid>
      </Grid>
    </>
  );
};

export default ProductList;
