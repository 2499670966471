import React, { useState } from "react";
import styles from "./LandingOne.module.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import img1 from "../../Assets/49368836.png";
import logo from "../../Assets/d2logo.png";
import Grid from "@mui/material/Grid";
import { AppBar, Backdrop, Box, Divider, Fade, Modal, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminLogin from "../Login/AdminLogin";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginModal } from "../../API/Reducer/menuReducer";
 const LandingOne = () => {
  const navigate=useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {adminLoginModalStatus} = useSelector((state) => state.menu);
  const isMediumOrLargerScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isNavActive, setIsNavActive] = useState(false);
 
  

  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  const style = {
    // position: 'relative',
    // top: '50%',
    // left: '50%',
    width:"100%",
    // transform: 'translate(-50%, -50%)',
    // marginLeft:"auto",
    // marginRight:"auto", 
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
    // display: "flex",
    // justifyContent: "center",
  };
  

  return (
    <>
      <AppBar
        position="relative"
        sx={{ p: 0, m: 0, b: 0, backgroundColor: "transparent" }}
      >
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item md={2} xs={2} sx={{display:"flex",gap:"5px",alignItems:"center"}}>
            <div style={{height:"40px",width:"40px",display:"flex",justifyContent:"center",
            alignItems:"center",overflow:"hidden" }}>
                <img src={logo} style={{objectFit:"contain",width:"100%",height:"100%"}}/>
            </div>
            <a href="/admin" className={styles.logo}>
              <span>D2</span>Super.
            </a>
          </Grid>

          <Grid item md={10} xs={10} className={styles.menu}>
            <nav className={styles.navbar}>
              <a href="#" style={{ "--i": 1 }}>
                Home
              </a>
              <a href="#" style={{ "--i": 2 }}>
                About
              </a>
              <a href="#" style={{ "--i": 3 }}>
                Contact
              </a>
              <a href="#" onClick={()=>{dispatch(adminLoginModal())}} style={{ "--i": 4 }}>
                Login
              </a>
            </nav>
          </Grid>
        </Grid>
      </AppBar>
      <Box sx={{ flex: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item container md={6} xs={12} className={styles.homeContent}>
            <Grid item xs={12} md={12}>
              <h1>
                <span>D2</span>Super!
              </h1>
            </Grid>
            <Grid item xs={12} md={12}>
              <h4>
                Transform Your Business Operations with Personalized Automation
                Solutions.
              </h4>
            </Grid>
            <Grid item xs={12} md={12}>
              <p>
                Connect strategic goals to the teams that help achieve them. See
                progress in real time, update stakeholders, and keep the
                organization on track.
              </p>
            </Grid>
            <Grid item xs={12} md={12}>
              <button className={styles.btn}>GET NOW</button>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            {!isSmallScreen && (
              <div className={styles.rmbs1}>
                <div className={styles.rmbs2}>
                  <img src={img1} alt="" />
                </div>
              </div>
            )}
            {isSmallScreen && (
              <div className={styles.imgcon}>
                <img src={img1} alt="" />
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        component="footer"
        sx={{
          bottom: 0,
          width: "100%",
          bgcolor: "#1f1f1f",
          color: "#fff",
        }}
      >
        <svg
          className={styles.footerwavesvg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className={styles.footerwavepath}
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <Grid container spacing={2}>
          {!isSmallScreen && (
            <Grid item md={12} xs={12} className={styles.contactcon}>
              <div className={styles.coninfo}>
                <LocationOnIcon className={styles.conicon}></LocationOnIcon>
                <div className={styles.coninfosub}>
                  <h3>Find us</h3>
                  <h5>Vishva tirumala apt; Mangalwar Peth, Miraj - 416410 </h5>
                </div>
              </div>
              <div className={styles.coninfo}>
                <PhoneEnabledIcon className={styles.conicon}></PhoneEnabledIcon>
                <div className={styles.coninfosub}>
                  <h3>Call us</h3>
                  <h5>+91 99600 08313</h5>
                </div>
              </div>
              <div className={styles.coninfo}>
                <MailOutlineIcon className={styles.conicon}></MailOutlineIcon>
                <div className={styles.coninfosub}>
                  <h3>Email</h3>
                  <h5>dineshyamkar8833@gmail.com</h5>
                </div>
              </div>
            </Grid>
          )}

          <Grid item md={12}>
            <hr style={{ color: "#fff" }} />
          </Grid>
          <Grid item md={12} xs={12} className={styles.contactcon}>
            <div>
              <div className={styles.coninfo}>
                <div className={styles.fotimgcon}>
                  <img src={logo} />
                </div>
                <a
                  href="/admin"
                  className={styles.logo}
                  style={{ color: "#fff" }}
                >
                  <span>D2</span>Super
                </a>
              </div>
              <p style={{ fontSize: "14px", color: "#cfccdf" }}>
                Connect strategic goals to the teams that help achieve them. See
                progress in real time, update stakeholders, and keep the
                organization on track.
              </p>
            </div>
            {!isSmallScreen && (
              <div className={styles.links}>
                <h5 onClick={(e)=>{navigate("/")}}>Home</h5>
                <h5>About</h5>
                <h5 onClick={(e)=>{navigate("/termscondition")}}>Terms & Condition</h5>
                <h5 onClick={(e)=>{navigate("/privacypolicy")}}>Privacy & Policy</h5>
                <h5>Contact</h5>
              </div>
            )}
            {isSmallScreen && (
            <div
              style={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "space-between",
                padding: "5px",
                gap:"10px",
              }}
            >
              <div className={styles.links}>
              <h5 onClick={(e)=>{navigate("/")}} style={{textAlign:"center"}}>Home</h5>
                <h5 style={{textAlign:"center"}}>About</h5>
                <h5 style={{textAlign:"center"}} onClick={(e)=>{navigate("/termscondition")}}>Terms & Condition</h5>
                <h5 style={{textAlign:"center"}} onClick={(e)=>{navigate("/privacypolicy")}}>Privacy & Policy</h5>
                <h5 style={{textAlign:"center"}}>Contact</h5>
              </div>
              <div>
                <div className={styles.mcon}>
                  <LocationOnIcon className={styles.conicon}></LocationOnIcon>
                  <h5>Vishva tirumala apt; Mangalwar Peth, Miraj - 416410</h5>
                </div>
                <div className={styles.mcon}>
                  <PhoneEnabledIcon
                    className={styles.conicon}
                  ></PhoneEnabledIcon>
                  <h5>+91 99600 08313</h5>
                </div>
                <div className={styles.mcon}>
                  <MailOutlineIcon className={styles.conicon}></MailOutlineIcon>
                  <h5>dineshyamkar8833@gmail.com</h5>
                </div>
              </div>
            </div>
            )}  
          </Grid>
          <Grid item md={12} xs={12} textAlign={"center"}>
            <h4>
              Copyright © 2024, All Right Reserved{" "}
              <span style={{ color: "#F5004F" }}>
                D2<span style={{ color: "#cfccdf" }}>Super</span>
              </span>
            </h4>
          </Grid>
        </Grid>
      </Box>


      <Modal      
        open={adminLoginModalStatus}
        onClose={()=>{dispatch(adminLoginModal())}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {/* <Fade in={adminLoginModalStatus}> */}
        <Box sx={{top:"50%",left:"50%",position:"absolute",transform: 'translate(-50%, -50%)'}}>
             <AdminLogin/>
          </Box>
        {/* </Fade> */}
      </Modal>
    </>
  );
};

export default LandingOne;
