import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import './UserStatsCard.scss';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setReturnAnswer } from '../../API/Reducer/confirmDialogReducer';

const UserStatsCard = () => {
    const dispatch = useDispatch();
    const { userCounts } = useSelector((state) => state.user);    
    
    const getCountValue=(isstatus=0)=>{
        if(isstatus===-1){
            const totalCount = userCounts.reduce((acc, item) => parseInt(acc,10) + parseInt(item?.count,10), 0);
            return totalCount || 0;
        }
        const filteredData = userCounts.filter(item => item.ustatus === isstatus);
        return filteredData?.[0]?.count || 0;        
    }

 
    

  return (
    <>
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} sx={{textAlign:"center",marginBottom:"10px"}}>
            <h2>Statistics</h2>      
      </Grid>
      <Grid item xs={6} md={6} sx={{paddingBottom:"10px",borderRight:"1px solid gray"}}>
        <div className='userstat-main'>     
           <div className="userstat-icon">
            <PeopleAltIcon className="icon"/>  
           </div>
            <span className="userstat-heading">Total Users</span>
            <span className="userstat-count">{getCountValue(-1)}</span>
      
        </div>
      </Grid>

      <Grid item xs={6} md={6} sx={{paddingBottom:"10px"}}>
      <div className='userstat-main'>     
           <div className="userstat-activeicon">
            <DoneIcon className="icon"/>  
           </div>
            <span className="userstat-heading">Active Users</span>
            <span className="userstat-count">{
                getCountValue(0)
                }</span>
        </div>
      </Grid>
    
      <Grid item xs={12} md={12} p={1}>
        <Divider/>
      </Grid>

      <Grid item xs={6} md={6} sx={{paddingBottom:"10px",borderRight:"1px solid silver"}}>
      <div className='userstat-main'>     
           <div className="userstat-expiredicon">
            <AccessAlarmIcon className="icon"/>  
           </div>
            <span className="userstat-heading">Expired Users</span>
            <span className="userstat-count">{getCountValue(1)}</span>
        </div>
      </Grid>
      <Grid item xs={6} md={6} sx={{paddingBottom:"10px"}}>
      <div className='userstat-main'>     
           <div className="userstat-blockedicon">
            <BlockIcon className="icon"/>  
           </div>
            <span className="userstat-heading">Blocked Users</span>
            <span className="userstat-count">{getCountValue(2)}</span>
        </div>
      </Grid>
    </Grid>
    </>
  )
}

export default UserStatsCard