import React, { useState } from 'react'
import { Grid, Paper,Button} from "@mui/material";
import style from './TaskPage.module.css'
import Bottombar from '../Appbar/Bottombar';
import NewTask from './NewTask';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import FixedLayout from '../Layout/FixedLayout';
import { useDispatch, useSelector } from 'react-redux';
import { handleEBBox, handleEBoxModal } from '../../API/Reducer/userReducer';

const TaskPage = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const {userStatusData} = useSelector((state) => state.user);
    const [onsubmit, setOnSubmit] = useState(false)    
    const { id } = useParams();
 
    const headerContent = (
      <Grid container spacing={1} p={1}>
      <Grid item xs={1} className={style.bckarwcon}
        onClick={(e)=>{
          id ? navigate(-1) : navigate("/home")
          }}>
        <ArrowBackIcon className={style.bckarow}/>
      </Grid>
      <Grid item xs={3} className={style.hading}>
          {id ? `Edit Task`:"Add Task"}
      </Grid>
      <Grid item xs={8} className={style.btncon}>      
       <Button
          color="success"
          type="button"
          size="small"
          variant="contained"
          onClick={(e)=>{
            if(userStatusData?.ustatus==1 || userStatusData?.ustatus==2){
                dispatch(handleEBoxModal());
                return;
              } 
            setOnSubmit(true)}}
            >
              Save
            </Button>
      </Grid>
     </Grid>
      );
  return (
    <>
  <FixedLayout header={headerContent} footer={<Bottombar/>}>      
         <Grid container p={1}>
           <Grid item xs={12}>
           <NewTask onsubmit={onsubmit} setOnSubmit={setOnSubmit}/>
          </Grid>
          </Grid>
   </FixedLayout>
  
    </>
  )
}

export default TaskPage