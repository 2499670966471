import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaskListMain,
  handleTaskSearchInput,
  setTaskLists,
} from "../../API/Reducer/taskReducer";
import TaskCard from "./TaskCard";
import {
  Paper,
  Box,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import "./TaskList.scss";
import { useLocation } from "react-router-dom";
import AlertComp from "../AlertMessageCom/AlertComp";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const TaskList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { taskLists, taskSearchInput, taskSearchInputClick, taskWhere } =
    useSelector((state) => state.task);
  const { activeItem } = useSelector((state) => state.menu);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [limit, setLimit] = useState(100);
  const [filteredBillData, setfilteredBillData] = useState([]);

  useEffect(() => {
    dispatch(setTaskLists([])); // Reset task lists to an empty array
    setPage(1); // Reset page to 1
    setHasMore(true); // Reset hasMore to true
  }, [location]);

  const fetchData = async (taskwhere) => {
    setLoading(true);
    const data = {
      isstatus: activeItem === "task" ? 0 : activeItem === "pending" ? 1 : 2,
      isorder: "desc",
      page,
      limit: limit,
      ...taskwhere,
    };
    // alert(data?.srch)
    await dispatch(getTaskListMain(data, false));
    window.scrollTo(0, 0);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, activeItem]);

  useEffect(() => {
    if (taskWhere?.isfilter === true) {
      fetchData(taskWhere);
    } else if (taskWhere?.isfilter === false) {
      fetchData({});
    }
  }, [taskWhere]);

  const handleLoadMore = (isNP = true) => {
    if (isNP == true) {
      setPage((prevPage) => prevPage + 1);
    } else {
      setPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const filteredData = taskLists
      .slice(0, taskLists.length - 1)
      .filter((task) =>
        task.search_string.toLowerCase().includes(taskSearchInput.toLowerCase())
      );
    setfilteredBillData(filteredData);
  }, [taskSearchInput, taskLists]);

  useEffect(() => {
    if (filteredBillData.length <= 0 && taskSearchInput !== "") {
      fetchData({
        srch: taskSearchInput,
        limit: 1000,
      });
    } else if (filteredBillData.length <= 0 && taskSearchInput == "") {
      fetchData();
    }
  }, [filteredBillData]);

  // const filteredBillData = taskLists.slice(0, -1); // Exclude the last item

  return (
    <div>
      <AlertComp />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "-20px",
          marginBottom: "20px",
        }}
      >
        <TextField
          label="Search"
          size="small"
          variant="standard"
          // fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                onClick={(e) => {
                  // dispatch(setTaskSearchInputClick(true))
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="start"
                onClick={(e) => {
                  dispatch(handleTaskSearchInput(""));
                  fetchData({});
                }}
              >
                <CloseIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            dispatch(handleTaskSearchInput(e.target.value));
          }}
          value={taskSearchInput}
        />
      </Box>
      <ul>
        {filteredBillData.map((item, index) => (
          <li key={item.id}>
            <Paper
              key={index}
              elevation={2}
              className={
                item?.isstatus == 0
                  ? "paperWithBorderTask"
                  : item?.isstatus == 1
                  ? "paperWithBorderPen"
                  : "paperWithBorderCmp"
              }
            >
              <TaskCard element={item} />
            </Paper>
          </li>
        ))}
      </ul>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px", // Adjust height as needed
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            handleLoadMore(false);
          }}
          sx={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            size: "small",
            padding: "5px 10px",
            borderRadius: "25px",
            fontWeight: "bold",
            textTransform: "none",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          <ArrowBack sx={{ fontSize: "20px", marginRight: "5px" }} />
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleLoadMore(true);
          }}
          sx={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            size: "small",
            padding: "5px 10px",
            borderRadius: "25px",
            fontWeight: "bold",
            textTransform: "none",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          Next
          <ArrowForward sx={{ fontSize: "20px", marginLeft: "5px" }} />
        </Button>
      </Box>
    </div>
  );
};

export default TaskList;
