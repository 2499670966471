import React, { useEffect, useState } from "react";
import style from "./DemoPage.module.css";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { handleUserChange, saveUser } from "../../API/Reducer/userReducer";
import {
  getErrorStatus,
  removeErrorStatus,
} from "../../API/Reducer/errorReducer";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import img from "../../Assets/d2logo.png";
const DemoPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      uname: "",
      uemail: "",
      paswd: "",
      mob: "",
      img: "",
    },
  });
  const [image, setImage] = useState();
  const onSubmit = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === "img") {
        formData.append("file", image); // Handle file separately
      } else {
        formData.append(key, data[key]);
      }
    });
    dispatch(saveUser(formData));
  };
  const fileChange = (e) => {
    setImage(e.target.files[0]);
  };
  const [first, setfirst] = useState("Test");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className={style.item1}>Image</div>
        <div className={style.item2}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} className={style.heading}>
              <span>Welecome</span> <span className={style.bckword}>Back</span>
            </Grid>
            <Grid item md={12} xs={6} className={style.inpContainer}>
              <Controller
                name="uname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="User Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.uname?.message)}
                    helperText={
                      errors?.uname?.message ? errors?.uname?.message : ""
                    }
                  />
                )}
                rules={{
                  required: "User name is required",
                  minLength:{value:3,message:"Minimum 3 characters required"}
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className={style.inpContainer}>
              <Controller
                name="uemail"                
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.uemail?.message)}
                    helperText={
                      errors?.uemail?.message ? errors?.uemail?.message : ""
                    }
                  />
                )}
                rules={{
                  required: "Email is required",                  
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className={style.inpContainer}>
              <Controller
                name="mob"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Mobile"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.mob?.message)}
                    helperText={
                      errors?.mob?.message ? errors?.mob?.message : ""
                    }
                  />
                )}
                rules={{
                  required: "Mobile number is required",
                  minLength:{value:10,message:"Minimum 10 digit required"}
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className={style.inpContainer}>
            <Controller
                name="paswd"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.paswd?.message)}
                    helperText={
                      errors?.paswd?.message ? errors?.paswd?.message : ""
                    }
                  />
                )}
                rules={{
                  required: "Password is required",
                  minLength:{value:3,message:"Minimum 3 characters required"}
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className={style.inpContainer}>
            <Controller
                name="img"
                control={control}
                render={({ field }) => (                  
                  <TextField
                    {...field}
                    type="file"
                    inputProps={{ accept: "image/*" }}                               
                    label="Image"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors?.img?.message)}
                    helperText={
                      errors?.img?.message ? errors?.img?.message : ""
                    }
                    onChange={(e) => {
                    field.onChange(e);
                    fileChange(e);
                  }}
                    
                  />
                )}
                rules={{
                  required: "Image is required",
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className={style.inpContainer}>
              <Button color="primary" variant="contained" type="submit">
                Login
              </Button>
            </Grid>
          </Grid>
        </div> */}

        <Grid container sx={{ height: "100vh" }}>
          <Grid
            item
            xs={12}
            sx={{
              height: "35%",
              position: "relative",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className={style.shape}></div>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "10%",
              display: "flex",
              gap: "5px",
              fontSize: "30px",
              fontWeight: "600",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <span>Welcome</span>
            <span style={{ color: "red" }}>back</span>
            {/* {first} */}
          </Grid>
          <Grid item xs={12} sx={{ height: "25%", padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  // value={first}
                  onChange={(e) => {
                    setfirst(e.target.value);
                  }}
                  label="User Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="enter user name"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{                  
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end"
                }}
              >
                <span style={{ color: "blueviolet" }}>Forget Password?</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "15%",
              padding: "20px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span style={{ fontSize: "28px", fontWeight: "800" }}>Log In</span>
            <span>
              <Button variant="contained" color="warning">
                <ArrowForwardIcon />
              </Button>
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              position: "relative",
              height: "15%",
              padding: "20px",
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <span>
              Haven't an account?{" "}
              <span style={{ color: "red", fontWeight: "600" }}>Sign Up</span>
            </span>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default DemoPage;
