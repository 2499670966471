import {
  Grid,
  Paper,
  TextField,
  InputAdornment,
  CircularProgress,
  Divider,
  Box,
} from "@mui/material";
import React, { useEffect, useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import style from "./HomePage.module.css";
import Bottombar from "../Appbar/Bottombar";
import HomePageMenu from "../Appbar/HomePageMenu";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useDispatch, useSelector } from "react-redux";
import { homeMenuSelect } from "../../API/Reducer/menuReducer";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout"; 
import {
  getCntByIsstatus,
  getTaskCount,
  getTaskList,
  handleTaskSearchInput,
  setTaskSearchInputClick,
} from "../../API/Reducer/taskReducer";
import FixedLayout from "../Layout/FixedLayout";
import { handleGetLocalStorage, handleLocalStorage } from "../../MIS/Global";
import { readSettingDataFObject } from "../../API/Reducer/settingReducer";
import TaskList from "../Task/TaskList";
 

const LazyImage = lazy(() => import("../GlobalComponent/LazyImage"));
const HomePage = () => {
  const navigate = useNavigate();
  const length = 0;
  const myArray = Array.from({ length }, (_, index) => index);
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.menu);
  const { taskCount,pendingDaysCall,taskSearchInput} = useSelector((state) => state.task);
  const {settings } = useSelector((state) => state.setting);
  
  useEffect(() => {
    if(pendingDaysCall===true){
    dispatch(getTaskCount({},false));
    }
  }, [pendingDaysCall]);
 
  
  const headerContent = (
    <Grid container spacing={1} p={1}>
      <Grid item xs={2} className={style.avatar}>
        <Avatar />
      </Grid>
      <Grid item xs={7} className={style.namecon}>
        <span>Hi,{handleGetLocalStorage("uname")}!</span>
        <span>Welcome Back</span>
      </Grid>
      <Grid
        item
        xs={3}
        className={style.notifycon}
        onClick={(e) => {
          handleLocalStorage({}, false, true);
          navigate("/login");
        }}
      >
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          color="warning"
          badgeContent={0}
          max={999}
        >
          <LogoutIcon sx={{ fontSize: "28px" }} />
        </Badge>
      </Grid>
      <Grid xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            <Grid item xs={4} onClick={() => dispatch(homeMenuSelect("task"))}>
              <Paper
                elevation={3}
                className={`${style.menucon} ${style.paper1}`}
              >
                <span>TASK</span>
                <div
                  className={`${style.divider} ${
                    activeItem === "task" ? style.activeDivider : ""
                  }`}
                ></div>
                <span>{dispatch(getCntByIsstatus(taskCount, 0))}</span>
              </Paper>
            </Grid>
            <Grid
              item
              xs={4}
              onClick={() => dispatch(homeMenuSelect("pending"))}
            >
              <Paper
                elevation={3}
                className={`${style.menucon} ${style.paper2}`}
              >
                <span>Pending</span>
                <div
                  className={`${style.divider} ${
                    activeItem === "pending" ? style.activeDivider : ""
                  }`}
                ></div>
                <span>{dispatch(getCntByIsstatus(taskCount, 1))}</span>
              </Paper>
            </Grid>
            <Grid
              item
              xs={4}
              onClick={() => dispatch(homeMenuSelect("complete"))}
            >
              <Paper
                elevation={3}
                className={`${style.menucon} ${style.paper3}`}
              >
                <span>COMPLETE</span>
                <div
                  className={`${style.divider} ${
                    activeItem === "complete" ? style.activeDivider : ""
                  }`}
                ></div>
                <span>{dispatch(getCntByIsstatus(taskCount, 2))}</span>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} sx={{ paddingTop: "10px" }}>
        <Paper>
          <Grid container spacing={0}>
            <Grid item xs={5} className={style.taskheading}>
              <h3>
                {activeItem === null
                  ? "Task"
                  : activeItem === "task"
                  ? "Task"
                  : activeItem === "pending"
                  ? "Pending"
                  : activeItem === "complete"
                  ? "Complete"
                  : "Task"}
              </h3>
            </Grid>
            <Grid item xs={7}>
              <TextField
                label="Search"
                size="small"
                variant="standard"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      onClick={(e) => {
                        dispatch(setTaskSearchInputClick(true))
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e)=>{dispatch(handleTaskSearchInput(e.target.value))}}
                value={taskSearchInput}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item xs={12} className={style.datainfo} sx={{display:"none"}}>
        Last {readSettingDataFObject(settings,'task','defulatloaddays')} days
      </Grid>
    </Grid>
  );

  
  return (
    <>
      <FixedLayout header={headerContent} footer={<Bottombar />}>      
        <Box p={1} mb={1}>
        <TaskList/>
        </Box>
      </FixedLayout>
    </>
  );
};

export default HomePage;
