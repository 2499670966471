import React, { useRef, useState } from "react";
import "./UserLogin.scss";
import { useForm } from "react-hook-form";
import AlertComp from "../AlertMessageCom/AlertComp";
import { useDispatch } from "react-redux";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  InputAdornment,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Slide,
  Typography,
  useMediaQuery,
  Paper,
} from "@mui/material";
import { genOtp, handleMaxLengthInput } from "../../MIS/Global";
import logo from "../../Assets/d2logo.png";
import bghead from "../../Assets/bglogin.svg";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { MuiOtpInput } from "mui-one-time-password-input";
import { otpEmail, saveUser } from "../../API/Reducer/userReducer";
import { formatUTCDate } from "../../MIS/Global";
import settings from '../../MIS/Settings.json';

const steps = ["Company", "Account", "Verify"];
const generateOtp=genOtp();
const UserSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ispaswdstatus, setIsPaswdStatus] = useState(false);
  const [cispaswdstatus, setCIsPaswdStatus] = useState(false);
  const textFieldRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [direction, setDirection] = useState("right");
  const [animate, setAnimate] = useState(false);
  const [otp, setOtp] = React.useState("");
  const [otpStatus, setOtpStatus] = React.useState(false);

  //   const theme = useTheme();
  //   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isStepOptional = (step) => step === 1;
  const isStepSkipped = (step) => skipped.has(step);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      cmpname:"",
      uname:"",
      umob: "",
      paswd: "",
      cpaswd: "",
      uemail:"",
      otp:"",
      regdate:formatUTCDate(new Date(),'ymd'),
      expdate:formatUTCDate(new Date(),'ymd',(parseInt(settings?.adminsetting?.expdays)*-1)),
      task:settings?.taskSetting,
    },
  });

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const onSubmit = (data) => {};
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  const handlePaswdChange = () => {
    setIsPaswdStatus(!ispaswdstatus);
  };

  const handleNext = async() => {
     setAnimate(false); // Reset animation
     let result=true;
     if(activeStep===0){
      result = await trigger(["cmpname"]);
     }else if(activeStep===1){
      result = await trigger(["uname","umob","uemail","paswd","cpaswd"]);
     }else if(activeStep===2){
        if(parseInt(otp)===parseInt(generateOtp)){
          setOtpStatus(false);  
          result=true;
        }else{
          setOtpStatus(true);
          result=false;
        }
     }
     if(result===false){
      return;
     }else if(result===true && activeStep===1){
      dispatch(otpEmail(getValues(),generateOtp,setActiveStep));
     }
     else if(result===true && activeStep===2){
      dispatch(saveUser(getValues()));
      return;
     }

     setTimeout(() => {
      if (activeStep === steps.length - 1) {
        // setActiveStep(0);
        navigate("/home");
      } else {
        setActiveStep((prev) => prev + 1);
      }
      setAnimate(true); // Trigger animation
    }, 100); // Delay to ensure the class is re-applied
  };

  const handleBack = () => {
    setAnimate(false); // Reset animation
    setTimeout(() => {
      setActiveStep((prev) => prev - 1);
      setAnimate(true); // Trigger animation
    }, 100); // Delay to ensure the class is re-applied
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep))
      throw new Error("You can't skip a step that isn't optional.");
    setDirection("left");
    setSkipped((prev) => new Set(prev).add(activeStep));
    setActiveStep((prev) => prev + 1);
  };

 
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TextField
            type="text"
            size="medium"
            fullWidth
            label="Business Name"
            variant="outlined"
            inputProps={{
              onInput: (event) => handleMaxLengthInput(event, 100),
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            {...register("cmpname", {
              required: "Business Name is required",
              minLength: {
                value: 3,
                message: "Business Name must be at least 3 character long",
              },
            })}
            error={!!errors.cmpname}
            helperText={errors.cmpname?.message}
          />
        );
      case 1:
        return (
          <>
            <TextField
              type="text"
              size="small"
              fullWidth
              label="User Name"
              variant="outlined"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 100),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              {...register("uname", {
                required: "User Namne is required",
                minLength: {
                  value: 3,
                  message: "User Name must be at least 3 character long",
                },
              })}
              error={!!errors.uname}
              helperText={errors.uname?.message}
            />
            <TextField
              type="number"
              size="small"
              fullWidth
              label="Mobile Number"
              variant="outlined"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 10),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StayCurrentPortraitIcon />
                  </InputAdornment>
                ),
              }}
              {...register("umob", {
                required: "Mobile Number is required",
                minLength: {
                  value: 10,
                  message: "Mobile Number must be at least 10 digit",
                },
              })}
              error={!!errors.umob}
              helperText={errors.umob?.message}
            />
            <TextField
              type="text"
              size="small"
              fullWidth
              label="Email"
              variant="outlined"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 100),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              {...register("uemail", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Enter a valid email address',
                },
              })}
              error={!!errors.uemail}
              helperText={errors.uemail?.message}
            />
            <TextField
              type={ispaswdstatus ? "text" : "password"}
              size="small"
              fullWidth
              label="Password"
              variant="outlined"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 20),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={handlePaswdChange}
                  >
                    {ispaswdstatus ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
              {...register("paswd", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 character long",
                },
              })}
              error={!!errors.paswd}
              helperText={errors.paswd?.message}
            />
            <TextField
              type={cispaswdstatus ? "text" : "password"}
              size="small"
              fullWidth
              label="Confirm Password"
              variant="outlined"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 20),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={()=>{setCIsPaswdStatus(!cispaswdstatus)}}
                  >
                    {cispaswdstatus ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
              {...register("cpaswd", {
                required: "Confirm Password is required",     
                validate: value => value === getValues('paswd') || "mismatched Password and Confirm Password",           
              })}
              error={!!errors.cpaswd}
              helperText={errors.cpaswd?.message}
            />
          </>
        );
      case 2:
        return (
          <>
            <h2>Verify OTP</h2>
            <MuiOtpInput
              value={otp}
              onChange={handleChange}
              type="number"
              TextFieldsProps={{ type: "number" }}             
            />
            {otpStatus && <h5 style={{color:"red"}}>Invalid otp</h5>}
            <h6>
              Your One-Time Password (OTP) has been sent to your registered
              mobile number/email address.
            </h6>
          </>
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <>
      <AlertComp />

      <div className="userreg-main">
        <div className="userreg-con1">
          <svg
            width="834"
            height="494"
            viewBox="0 0 834 494"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="footerwavesvg"
          >
            <path
              d="M0 0H834V494L323.919 297.098C228.557 260.286 118.085 295.492 68.758 378.414L0 494V0Z"
              fill="#FF4B2B"
            />
            <path
              d="M377.597 253.148C317.925 231.227 295.947 164.604 332.477 116.378L420.628 0.000261731L834 0L834 420.815L377.597 253.148Z"
              fill="#FF765E"
            />
            <path
              d="M646.104 257.071C614.926 244.182 606.735 207.296 629.964 184.392L834 0V338.482L646.104 257.071Z"
              fill="#FF9E8D"
            />
            <path
              d="M43.5875 172.538C74.7628 184.711 88.7745 217.895 74.5833 245.946L5.58727e-07 393.37L1.19785e-05 155.519L43.5875 172.538Z"
              fill="#FF9E8D"
            />
          </svg>

          <div id="head">
            Create Your <br></br> Account
          </div>
        </div>
        <div className="userreg-content">
          <form
            className="userreg-form"
            onSubmit={handleSubmit(onSubmit, onError)}
            autoComplete="off"
          >
            {getStepContent(activeStep)}
          </form>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              variant="contained"
              size="medium"
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button
                variant="contained"
                size="medium"
                color="inherit"
                onClick={handleSkip}
                sx={{ mr: 1, display: "none" }}
              >
                Skip
              </Button>
            )}
            <Button variant="contained" size="medium" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </div>
        <div className="userreg-footer">
          <span
            onClick={() => {
              navigate("/login");
            }}
          >
            Already have an account? <a className="userreg-signup">Login</a>
          </span>
        </div>
      </div>
    </>
  );
};

export default UserSignUp;
