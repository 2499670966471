import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { IconButton, ImageList, ImageListItem, Modal, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./NewImagesUpload.module.css";
import { useParams } from "react-router-dom";
import { getImage } from "../../MIS/Global";
import { useDispatch } from "react-redux";
import { setAlertData } from "../../API/Reducer/alertReducer";
import AlertComp from "../AlertMessageCom/AlertComp";
import { deleteTaskImage } from "../../API/Reducer/taskReducer";
import Resizer from "react-image-file-resizer";
import imageCompression from 'browser-image-compression';

const NewImagesUpload = ({ files, setFiles, editImages, setEditImages }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const [singleImage, setSingleImage] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading

  const handleOpen = (imgpath) => {
    setOpen(true);
    if (id > 0) {
      // setSingleImage(getImage("public/" + imgpath));
      setSingleImage(getImage(imgpath));
    } else {
      setSingleImage("");
    }
  };

  useEffect(() => {
    if (id > 0 && editImages.length > 0) {
      setImages(editImages);
    }
  }, [id, editImages]);

  const onSubmit = (data) => {
    // Handle form submission
  };

  const resizeAndCompressImage = async (file) => {
    try {
      // Resize the image
      const resizedImage = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1080, // Max width
          1080, // Max height
          "JPEG", // Output format
          70, // Quality (0-100)
          0, // Rotation
          (uri) => {
            resolve(uri);
          },
          "file" // Output type (base64 or file)
        );
      });

      // Compress the image to be within 2MB if necessary
      const options = {
        maxSizeMB: 2,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(resizedImage, options);
      const finalFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
  
      return finalFile;
      // return compressedFile;
    } catch (error) {
      dispatch(setAlertData({ message: "Error processing image", severity: "error" }));
      throw error;
    }
  };

  const handleImageChange = async (event) => {
    setLoading(true); // Start loading
    const newFiles = Array.from(event.target.files);

    // Process each file for resizing and compression
    const processedFilesPromises = newFiles.map(async (file) => {
      try {
        const resizedAndCompressedFile = await resizeAndCompressImage(file);
        return {
          file: resizedAndCompressedFile,
          url: URL.createObjectURL(resizedAndCompressedFile),
        };
      } catch (error) {
        dispatch(setAlertData({ message: "Error processing image", severity: "error" }));
        return null;
      }
    });

    const processedFiles = await Promise.all(processedFilesPromises);

    // Filter out any null values from failed processing
    const validProcessedFiles = processedFiles.filter((file) => file !== null);

    const newImageUrls = validProcessedFiles.map((processed) => processed.url);
    const newProcessedFiles = validProcessedFiles.map((processed) => processed.file);

    setImages((prevImages) => [...prevImages, ...newImageUrls]);
    setFiles((prevFiles) => [...prevFiles, ...newProcessedFiles]);

    setLoading(false); // End loading
  };

  const handleDelete = (index) => {
    setImages(images.filter((_, i) => i !== index));
    setEditImages(images.filter((_, i) => i !== index));
    setFiles(files.filter((_, i) => i !== index));
    if (id > 0 && editImages.length > 0) {
      let path = images.filter((_, i) => i === index);
      let imgArray = images.filter((_, i) => i !== index);
      dispatch(
        deleteTaskImage({
          id: id,
          imgpath: path?.[0]?.imgpath,
          imgpaths: imgArray,
        })
      );
    }
  };

  const triggerFileUpload = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "uploadFile" })
    );
  };

  const handleClose = () => setOpen(false);

  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 50, y: 50 });

  const handleMouseMove = (e) => {
    const rect = containerRef.current.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setPosition({ x, y });
  };

  const handleWheel = (e) => {
    const zoomChange = e.deltaY * -0.001; // Adjust zoom speed
    setScale((prevScale) => Math.min(Math.max(prevScale + zoomChange, 1), 3)); // Constrain zoom level between 1x and 3x
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        container.removeEventListener('wheel', handleWheel);
      };
    }
  }, [])

  return (
    <>
      <AlertComp />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h5
          style={{
            color: "#ff0000",
            display: `${id > 0 && editImages.length > 0 ? "" : "none"}`,
          }}
        >
          * To upload a new photo, first delete all existing images.
        </h5>
        <Controller
          name="images"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <input
                type="file"
                multiple
                accept="image/*"
                className={styles.fileInput}
                id="fileInput"
                name="files[]"
                onChange={(e) => {
                  handleImageChange(e);
                  field.onChange(e);
                }}
              />
              <label
                htmlFor="fileInput"
                className={styles.fileInputLabel}
                style={{
                  display: `${id > 0 && editImages.length > 0 ? "none" : ""}`,
                }}
              >
                <CloudUploadIcon className={styles.fileInputIcon} />
                Photos
              </label>
            </>
          )}
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <ImageList
            sx={{ width: "100%", height: "100%", marginTop: "10px" }}
            cols={3}
            rowHeight={164}
          >
            {images.map((img, index) => (
              <ImageListItem
                key={index}
                className={styles.imageListItem}
                onClick={(e) => {
                  if (id > 0 && editImages.length > 0) {
                    handleOpen(img?.imgpath);
                  }
                }}
              >
                <img
                  src={
                    id > 0 && editImages.length > 0
                      ? getImage(img?.imgpath)
                      : img
                  }
                  alt={`uploaded ${index}`}
                  loading="lazy"
                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                />
                <IconButton
                  aria-label="delete"
                  className={styles.deleteIcon}
                  onClick={() => handleDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </form>

      <Modal open={open} onClose={handleClose}>
        <div
          className={styles.interactive_zoom_container}
          ref={containerRef}
          onMouseMove={handleMouseMove}
          onWheel={handleWheel}
          style={{
            transformOrigin: `${position.x}% ${position.y}%`,
            transform: `scale(${scale})`,
          }}
        >
          <img
            src={singleImage}
            alt="Zoomable"
            className={styles.interactive_zoom_image} 
          />
        </div>
      </Modal>
    </>
  );
};

export default NewImagesUpload;
