import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
 import { setAlertData } from "./alertReducer";
import { setTaskLists } from "./taskReducer";
  

const menuReducer = createSlice({
    name: 'menu',
    initialState: {
        activeItem:"task",
        fabButton:false,
        adminLoginModalStatus:false,
        activeBottomMenuStatus:"Home",
        newTaskDrawer:false,
        forgotPaswdModal:false,
    },

    reducers: {           
        setActiveItem(state, action) {
            state.activeItem = action.payload;
        }, 
        setFabButton(state, action) {
            state.fabButton = action.payload;
        },
        setAdminLoginModal(state, action) {
          state.adminLoginModalStatus = action.payload;
       }, 
       setActiveBottomMenu(state, action) {
        state.activeBottomMenuStatus = action.payload;
       },  
       setNewTaskDrawer(state, action) {
        state.newTaskDrawer = action.payload;
       },
       setForgotPaswdModal(state, action) {
        state.forgotPaswdModal = action.payload;
       },       
                     
    }
});

export const {setActiveItem,setFabButton,setAdminLoginModal,setActiveBottomMenu,setNewTaskDrawer,setForgotPaswdModal} = menuReducer.actions;
export default menuReducer.reducer;
 
export const homeMenuSelect = (data) => {
    return function actionData(dispatch) {
      dispatch(setTaskLists([]));
      dispatch(setActiveItem(data));
    };
  };

  export const fabButtonDisabled = (data) => {
    return function actionData(dispatch) {
      dispatch(setFabButton(data));
    };
  };
 
  export const adminLoginModal = (data) => {
    return function actionData(dispatch,getState) {
      const {adminLoginModalStatus}=getState().menu;
      dispatch(setAdminLoginModal(!adminLoginModalStatus));
    };
  };

  export const activeBottomMenu = (data) => {
    return function actionData(dispatch) {
      dispatch(setActiveBottomMenu(data));
    };
  };

  export const handleNewTaskDrawer = (data) => {
    return function actionData(dispatch,getState) {
      const {newTaskDrawer}=getState().menu;
      dispatch(setNewTaskDrawer(!newTaskDrawer));
    };
  };

  export const handleForgotPasswordModal = (data) => {
    return function actionData(dispatch,getState) {
      const {forgotPaswdModal}=getState().menu;
      dispatch(setNewTaskDrawer(!forgotPaswdModal));
    };
  };