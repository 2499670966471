import React from 'react'
import style from './LandingPage.module.css'
import AvTimerIcon from '@mui/icons-material/AvTimer';
import img from '../../Assets/landing.png';
import logo from '../../Assets/d2logo.png';
import { Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const LandingPage = () => {
  const navigate=useNavigate();
  return (
    <>
    <div className={style.container}>
     <div className={style.iconcon}>      
      <img src={logo} alt="" srcset="" />
      {/* <AvTimerIcon sx={{fontSize:"80px"}}/> */}
     </div>
     <div className={style.headingcon}>
         <h1>D2</h1><h1>Super</h1>
     </div>
     <div className={style.imgcon}>
         <img src={img} alt="" srcset="" />
     </div>
     <div className={style.descheading}>
       <h2 className={style.infoheading}>Simplify Your Business</h2>
     </div>
      <div className={style.descheading}>
       <h2 className={style.infotext}>Tips and Tricks for Streamlined Organization</h2>
     </div>
     <div className={style.btncon}>
       <button onClick={()=>{navigate("/home")}} className={style.getStartedBtn}>Get Started</button>
     </div>
    </div>
    </>
  )
}

export default LandingPage