import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const sections = [
  {
    title: 'Task',
    description: '',
    items: [
      { icon: <AddIcon/>, label: 'New Task',path:"task" },
      { icon: <PlaylistAddCheckIcon/>, label: 'Task List', path:"tasklist"},    
    ],
    urole:0,

  },
  {
    title: 'User',
    description: '',
    items: [
      { icon: <PersonAddAltIcon/>, label: 'New User',path:"teamlist" },
     ],
     urole:1,
  },
  // Add more sections as needed
];

const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

const CollapsibleList = ({ title, description, items}) => {
  const [open, setOpen] = React.useState(false);
  const navigate=useNavigate();

  return (
    <Box
      sx={{
        bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
        pb: open ? 2 : 0,
       }}
    >
      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 0 : 2.5,
          '&:hover, &:focus': { '& svg': { opacity: 1 } },
          
        }}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: 'medium',
            lineHeight: '20px',
            mb: '2px',
          }}
          secondary={description}
          secondaryTypographyProps={{
            noWrap: true,
            fontSize: 12,
            lineHeight: '16px',
            color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
          }}
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 1,
            transform: open ? 'rotate(-180deg)' : 'rotate(180deg)',
            transition: '0.2s',
          }}
        />
      </ListItemButton>
      {open &&
        items.map((item) => (
          <ListItemButton
            key={item.label}
            sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
            onClick={(e)=>{navigate("/"+item?.path)}}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
            />
          </ListItemButton>
        ))}
    </Box>
  );
};

export default function SideBarMenu() {
  const {userStatusData} = useSelector((state) => state.user);
  const currentUrole = userStatusData?.urole==1?0:null; 
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            // background: { paper: 'rgb(5, 30, 52)' },
            background: { paper: '#4d4352' },
          },
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: 256, height: '100vh', overflow: 'auto' }}>
          <FireNav component="nav" disablePadding>
            <ListItemButton component="a" href="#customized-list">
              <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary="D2 Super"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItem component="div" disablePadding sx={{display:"none"}}>
              <ListItemButton sx={{ height: 56 }}>
                <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Project Overview"
                  primaryTypographyProps={{
                    color: 'primary',
                    fontWeight: 'medium',
                    variant: 'body2',
                  }}
                />
              </ListItemButton>
              <Tooltip title="Project Settings">
                <IconButton
                  size="large"
                  sx={{
                    '& svg': {
                      color: 'rgba(255,255,255,0.8)',
                      transition: '0.2s',
                      transform: 'translateX(0) rotate(0)',
                    },
                    '&:hover, &:focus': {
                      bgcolor: 'unset',
                      '& svg:first-of-type': {
                        transform: 'translateX(-4px) rotate(-20deg)',
                      },
                    },
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      height: '80%',
                      display: 'block',
                      left: 0,
                      width: '1px',
                      bgcolor: 'divider',
                    },
                  }}
                >
                  <Settings />
                  <ArrowRight />
                </IconButton>
              </Tooltip>
            </ListItem>
            {/* <Divider /> */}
            {sections
            .filter((section) => currentUrole === null || section.urole === currentUrole)
            .map((section, index) => (
              <CollapsibleList
                key={index}
                title={section.title}
                description={section.description}
                items={section.items}                
              />
            ))}
          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}
