import React from 'react'
import './ExpirePage.scss'
import expire from '../../Assets/expire.png';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { handleLocalStorage } from '../../MIS/Global';

 const ExpirePage = () => {
  return (
    <>
    <div className="emain">      
        <div className='eimgcon'>
          <img src={expire} alt="Expire Image" srcset="" />
         </div>
         <div className='ebtnback' onClick={(e)=>{
            handleLocalStorage({},false,true);
            window.location.href="/login";
         }}>
          <ArrowBackIosIcon/>
         </div>
         <div>
            <h1>Upgrade to Premium</h1>
         </div>
         <div className="ebtncon">
            <a className='button-like'
             href="tel:+919960008313">
              <CallIcon/> +91 99600 08313
            </a>
         </div>
         <div className="einfocon">
            <h2>Transform Your Business Operations with Personalized Automation Solutions</h2>
            <p>Connect strategic goals to the teams that help achieve them. See progress in real time, update stakeholders, and keep the organization on track.</p>
         </div>
    </div>
    </>
  )
}

export default ExpirePage