import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  CssBaseline,
  Grid,
  Avatar,
  Paper,
  Drawer,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AdminSideBarMenu from "../Appbar/AdminSideBarMenu";
import Logout from "@mui/icons-material/Logout";
import { handleAdminGetLocalStorage, handleAdminSetLocalStorage } from "../../MIS/Global";
import { useNavigate } from "react-router-dom";

const AdminLayout = ({ children, color = "#fff" }) => {
  const navigate=useNavigate();
  const [menudrawer, setMenuDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const id=handleAdminGetLocalStorage("id");
     if(id<=0 || id==undefined){
       navigate("/");return;}
    
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppBar position="sticky" color="secondary" sx={{ p: 0, m: 0, b: 0 }}>
        <Grid container spacing={1} p={1}>
          <Grid
            item
            md={10}
            xs={10}
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <MenuIcon
              onClick={(e) => {
                setMenuDrawer(!menudrawer);
              }}
            />
            <h3
              onClick={(e) => {
                window.location.href = "/admin";
              }}
            >
              <span style={{ color: "#F5004F" }}>D2</span> Super
            </h3>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              cursor:"pointer"
            }}
            onClick={handleClick}
          >
            <Avatar sx={{ height: "25px", width: "25px" }} />
          </Grid>
        </Grid>
      </AppBar>

      <Box
        sx={{
          flex: 1,
          position: "relative",
          py: { xs: 2, sm: 3, md: 0 },
          m: 0,
          mb: 8,
          backgroundColor: `${color}`,
          minHeight: "100vh",          
        }}
      >
        {children}
      </Box>
      <Box
        component="footer"
        sx={{
          position: "relative",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          bgcolor: "background.paper",
        }}
      >
        <Paper sx={{ backgroundColor: "#ffe9ff", padding: "10px" }}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <h5>
                Copyright © 2024, All Right Reserved{" "}
                <span style={{ color: "#F5004F" }}>D2</span>Super
              </h5>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Drawer
        anchor="left"
        open={menudrawer}
        onClose={(e) => {
          setMenuDrawer(!menudrawer);
        }}
      >
        <AdminSideBarMenu />
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={(e)=>{handleClose();
          handleAdminSetLocalStorage({},false);
          navigate("/");
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AdminLayout;
