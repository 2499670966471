import React from 'react'
import AdminLayout from '../Layout/AdminLayout'

const AdminDashboard = () => {
  return (
    <>
    <AdminLayout>
      <h1>Dashboard</h1>
    </AdminLayout>
    </>
  )
}

export default AdminDashboard