import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  InputAdornment,
  Fab,
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Checkbox,
} from "@mui/material";
import Bottombar from "../Appbar/Bottombar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import style from "./TaskListPage.module.css";
import TaskCard from "./TaskCard";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import TaskTab from "./TaskTab";
import { getTaskList, handleTaskSearchInput, handleTaskWhere, setTaskSearchInputClick } from "../../API/Reducer/taskReducer";
import { useDispatch, useSelector } from "react-redux";
import FixedLayout from "../Layout/FixedLayout";
import { formatUTCDate } from "../../MIS/Global";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import dayjs from "dayjs";
import TaskList from "./TaskList";

const TaskListPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.menu);
  const { taskSearchInput } = useSelector((state) => state.task);
  const { settingList } = useSelector((state) => state.setting);
  const [draweropen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd", 0))
  );
  const [endDate, setEndDate] = useState(
    dayjs(formatUTCDate(new Date(), "ymd", 0))
  );
  const [searchInput, setSearchInput] = useState("");
  const [isDate, setIsDate] = useState(false);

  const handleDateChange=(newvalue,key)=>{
    if(key==="to"){
      setEndDate(dayjs(formatUTCDate(new Date(newvalue), "ymd", 0)))
    }else if(key==="clear"){
      setEndDate(dayjs(formatUTCDate(new Date(), "ymd", 0)))
      setStartDate(dayjs(formatUTCDate(new Date(), "ymd", 0)))
    }
    else{
      setStartDate(dayjs(formatUTCDate(new Date(newvalue), "ymd", 0)))
    }
  }

  const headerContent = (
    <Grid container spacing={1}>
      <Grid
        item
        xs={1}
        className={style.bckarwcon}
        onClick={(e) => {
          navigate("/home");
        }}
      >
        <ArrowBackIcon className={style.bckarow} />
      </Grid>
      <Grid item xs={6} className={style.hading}>
        Task List
      </Grid>
      <Grid item xs={5} className={style.btncon}>
        <IconButton
          onClick={() => setDrawerOpen(!draweropen)}
          color={filter ? "primary" : "default"}
        >
          {/* <FilterAltIcon /> */}
          {filter ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TaskTab />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className={style.dateinfo} >
        <Checkbox
          sx={{display:"none"}}
          defaultChecked
          size="medium"
          sx={{ padding: 0, margin: 0 }}
          color="primary"
          checked={isDate}
          onChange={(e) => {
            setIsDate(e.target.checked);
          }}
        />
        <span style={{ color: "blueviolet" }}>DATE</span>
        <h5>
          {formatUTCDate(new Date(startDate), "dmy")} To{" "}
          {formatUTCDate(new Date(endDate), "dmy")}
        </h5>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ paddingBottom: "5px", display: "flex", justifyContent: "end" }}
      >
        <TextField
          label="Search"
          size="small"
          variant="standard"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="start"
                onClick={(e) => {
                  dispatch(setTaskSearchInputClick(true))
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e)=>{dispatch(handleTaskSearchInput(e.target.value))}}
          value={taskSearchInput}
        />
      </Grid>
    </Grid>
  );
  return (
    <>
      <FixedLayout header={headerContent} footer={<Bottombar />}>
       <Box p={1} mb={1}>
         <TaskList/>
        </Box>
      </FixedLayout>

      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            // height: "80vh",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "15px",
          },
        }}
        open={draweropen}
        anchor="bottom"
        onClose={(e) => {
          setDrawerOpen(!draweropen);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                size="small"
                defaultValue={startDate}
                onChange={(newvalue) => {
                  handleDateChange(newvalue, "from");
                  dispatch(handleTaskWhere({
                    fdate:formatUTCDate(new Date(newvalue), "ymd"),
                    tdate:formatUTCDate(new Date(endDate), "ymd")
                  }))
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To"
                size="small"
                defaultValue={endDate}
                onChange={(newvalue) => {
                  handleDateChange(newvalue,"to");
                  dispatch(handleTaskWhere({
                    fdate:formatUTCDate(new Date(startDate), "ymd"),
                    tdate:formatUTCDate(new Date(newvalue), "ymd")
                  }))
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <div className={style.drawerclose}>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={(e) => {
              // getTasks(2);
              setDrawerOpen(!draweropen);
              setFilter(!filter);
              dispatch(handleTaskWhere({
                  fdate:formatUTCDate(new Date(startDate), "ymd"),
                  tdate:formatUTCDate(new Date(endDate), "ymd"),
                  isfilter:true,
              }))
            }}
          >
            <ThumbUpOffAltIcon sx={{ mr: 1 }} />
            Apply
          </Fab>
          <Fab
            variant="extended"
            size="small"
            color="error"
            onClick={(e) => {
              // getTasks(0)
              setDrawerOpen(!draweropen);
              setIsDate(true);
              setFilter(!filter);
              handleDateChange("","clear");
              dispatch(handleTaskWhere({
                  fdate:formatUTCDate(new Date(), "ymd"),
                  tdate:formatUTCDate(new Date(), "ymd"),
                  isfilter:false,
              }))
            }}
          >
            <CloseIcon sx={{ mr: 1 }} />
            Clear
          </Fab>
        </div>
      </Drawer>
    </>
  );
};

export default TaskListPage;
