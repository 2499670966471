import React, { useEffect, useState } from 'react'
import './EBBox.scss'
import { Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { handleGetLocalStorage, handleLocalStorage } from '../../MIS/Global';
import { getUsersStatus, handleEBBox, handleEBoxModal } from '../../API/Reducer/userReducer';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import BlockIcon from '@mui/icons-material/Block';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const EBBox = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const {userStatusData,ebBox,ebBoxModal} = useSelector((state) => state.user);
    const [open, setOpen] = useState(false);
    const [cardinfo, setCardInfo] = useState({
      msg:"Your Subscription Has Expired – Renew Now to Keep Enjoying Our Services!",
      heading:"Subscription Expire",
      icon:<AccessAlarmsIcon sx={{fontSize:"40px"}}/>,
      path:"/home",            
    });

    useEffect(() => {
        if(ebBox===false){
            dispatch(getUsersStatus({id:handleGetLocalStorage('id',false),
            cmpid:handleGetLocalStorage('cmpid',false)},false));            
         }
    },[])

    useEffect(() => {
      if(Object.keys(userStatusData).length>0 && ebBox===false){        
        if(userStatusData?.ustatus == 1){
          setCardInfo({
            msg:"Your Subscription Has Expired – Renew Now to Keep Enjoying Our Services!",
            heading:"Subscription Expire",
            icon:<AccessAlarmsIcon sx={{fontSize:"40px"}}/>,
            path:"/expire",            
          });
          dispatch(handleEBoxModal());
        }else if(userStatusData?.ustatus == 2){
          setCardInfo({
            msg:"Your account has been blocked due to security reasons. Please contact the administrator to resolve this issue and regain access.",
            heading:"Account Block",
            icon:<BlockIcon sx={{fontSize:"40px"}}/>,
            path:"/login",
          });
          dispatch(handleEBoxModal());
          handleLocalStorage({},false,true);
        }
         dispatch(handleEBBox());
      }
    },[userStatusData])
    
    const handleClose=()=>{
       dispatch(handleEBoxModal());
       navigate(cardinfo?.path);
    }

  return (
    <>
       <Dialog
         sx={{
                "& .MuiDialog-paper": {
                margin: 5,
                padding: 0,
                width: 'auto', // Adjust width
                height: 'auto', // Adjust height
                overflow: 'hidden', // Hide overflow if any
                borderRadius:'20px',
                backgroundColor:'#FC2638',
            }
        }}
        open={ebBoxModal}
        TransitionComponent={Transition}
        keepMounted
       >
         <div className="serviceBox">
                <div className="service-icon">
                     {cardinfo?.icon} 
                </div>
                <h3 className="title">{cardinfo?.heading}</h3>
                <p className="description">{cardinfo?.msg}</p>
                <div className="btncon">
                  <Button type ="button" variant='outlined'
                  onClick={(e)=>{
                    handleClose();                    
                  }}
                  >Ok</Button>
                </div>
             </div>           
      </Dialog>
    </>
  )
}

export default EBBox