import React, { useEffect, useRef, useState } from "react";
import "./AdminLogin.scss";
import logo from "../../Assets/d2logo.png";
import AlertComp from "../AlertMessageCom/AlertComp";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { InputAdornment, TextField } from "@mui/material";
import { handleMaxLengthInput } from "../../MIS/Global";
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { adminLoginModal } from "../../API/Reducer/menuReducer";
import { adminLogin } from "../../API/Reducer/userReducer";
const AdminLogin = () => {
  const dispatch=useDispatch();
  const [ispaswdstatus, setIsPaswdStatus] = useState(false)
  const textFieldRef = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      umob: "",
      paswd: "",
    },
  });
  const handlePaswdChange=()=>{
    setIsPaswdStatus(!ispaswdstatus);
  }
  // useEffect(() => {
  //   if (textFieldRef.current) {
  //     textFieldRef.current.focus();
  //   }
  // }, []);

  const onSubmit = (data) => {
    dispatch(adminLogin(data));
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  return (
    <>
      <AlertComp />
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
      <Grid container spacing={0} className="adminlogin-main">
    
        <Grid item md={6} xs={12} className="adminlogin-firstcont">
          <Grid container spacing={2}>
          
          <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"center", alignItems:"center"}}>
            <div className="adminlogin-logocon">
               <img src={logo}/> 
            </div>
          </Grid>
          <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"center", alignItems:"center"}}>
            <h1>Sign In</h1>
          </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                inputRef={textFieldRef}
                type="number"
                size="small"
                fullWidth
                label="Mobile Number"
                inputProps={{
                  onInput: (event) => handleMaxLengthInput(event, 10),
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><StayCurrentPortraitIcon/></InputAdornment>,
                }}
                {...register("umob", {
                  required: "Mobile Number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile Number must be at least 10 digit long",
                  },
                })}
                error={!!errors.umob}
                helperText={errors.umob?.message}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                type= {ispaswdstatus?'text':'password'}
                size="small"
                fullWidth
                label="Password"
                inputProps={{
                  onInput: (event) => handleMaxLengthInput(event, 20),
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end" sx={{cursor:"pointer"}} onClick={handlePaswdChange}>
                    {
                        ispaswdstatus?<VisibilityIcon/>:<VisibilityOffIcon/>
                    }
                     </InputAdornment>,
                     startAdornment: <InputAdornment position="start" sx={{cursor:"pointer"}}>
                        <LockIcon/>
                      </InputAdornment>,
                }}
                {...register("paswd", {
                  required: "Password is required",                
                })}
                error={!!errors.paswd}
                helperText={errors.paswd?.message}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"center", alignItems:"center"}}>
            <button type="submit">Sign In</button>
            </Grid>
          </Grid>
          <div className="adminlogin-close" sx={{display:{md:"none", xs:"block"}}}
          onClick={()=>{dispatch(adminLoginModal());}}>
                <CloseIcon/>
          </div>
        </Grid>
        <Grid item md={6} xs={6} sx={{position:"relative",display:{md:"block", xs:"none"} }}>
          <div className="adminlogin-secondcon">
            <h1>Hello, Friend</h1>
            <p>Enter your personal details and start journey with us</p>
          </div>
            <div className="adminlogin-close" onClick={()=>{dispatch(adminLoginModal());}}>
                <CloseIcon/>
            </div>
         </Grid>
       
      </Grid>
      </form>
     
    </>
  );
};

export default AdminLogin;
