import React from 'react'
import Grid from '@mui/material/Grid'
import LandingLayout from '../Layout/LandingLayout'
import './TermsCondition.scss'

const TermsCondition = () => {
  return (
    <>
      <LandingLayout bgcolor={"#fff"}>
      <Grid container spacing={0}>
       <Grid item xs={12} md={12} className="tc-main-heading">
         <h1>Terms and Condition</h1>
       </Grid>
       <Grid item xs={12} md={12} p={2}>
         <p>Welcome to D2 Super, a CRM(Customer relationship management) management application designed for mobile shop organization and team. By accessing or using our app, you agree to comply with and be bound by the following Terms & Conditions. Please read them carefully.</p>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Acceptance of Terms</h2>
         <li>By downloading, installing, or using D2 Super, you agree to be bound by these Terms & Conditions ("Terms"). If you do not agree with these Terms, you must not use the App.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Use of the App</h2>
         <li>The App is intended for use by mobile shop owners, managers, and employees for managing tasks, schedules, and other work-related activities.</li>
         <li>You must provide accurate information when creating an account and using the App.</li>
         <li>You agree not to misuse the App, including but not limited to, unauthorized access, data tampering, or using the App for illegal activities.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>User Responsibilities</h2>
         <li>You are responsible for maintaining the confidentiality of your login credentials and any activities that occur under your account.</li>
         <li>You must not share your account with others or use another user's account without permission.</li>
         <li>You agree to comply with all applicable laws and regulations while using the App.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Intellectual Property</h2>
         <li>All content, features, and functionality of the App, including but not limited to text, graphics, logos, and software, are the exclusive property of D2 Super or its licensors and are protected by intellectual property laws.</li>
         <li>You may not copy, modify, distribute, sell, or lease any part of the App or its content without our prior written consent.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Subscription and Payment</h2>
         <li>Certain features of the App may require a subscription or payment. 
         By subscribing or making a payment, you agree to the pricing, payment terms, 
         and subscription policies provided at the time of purchase.</li>
         <li>
         Payment for subscriptions can be made through both offline modes. For offline payments, please contact D2 Super directly to arrange payment and access to subscription features.
         </li>
         <li>Subscription fees are non-refundable except as required by law.</li>      
         <li>You are responsible for ensuring that payments are made promptly and that any offline payment arrangements are completed as agreed.</li>   
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Limitation of Liability</h2>
         <li>D2 Super is not liable for any direct, indirect, incidental, special, or consequential damages arising out of your use of the App.</li>
         <li>We do not guarantee the accuracy, completeness, or reliability of the App's content or functionality.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Termination</h2>
         <li>We reserve the right to terminate or suspend your account at our discretion, with or without notice, if you violate these Terms or engage in any prohibited activities.</li>
         <li>Upon termination, you must cease all use of the App and delete any copies of the App from your devices.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Amendments</h2>
         <li>We may modify these Terms at any time by posting the revised version on our website or within the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Governing Law</h2>
         <li>These Terms are governed by and construed in accordance with the laws of Miraj, Maharashtra jurisdiction; without regard to its conflict of law principles.</li>
         <li>Any disputes arising out of or relating to these Terms or the App shall be resolved exclusively in the courts of Miraj, Maharashtra jurisdiction.</li>
       </Grid>
     </Grid>
      </LandingLayout>  
   
    </>
  )
}

export default TermsCondition