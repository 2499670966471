import { Fab, Drawer, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
 
import { useNavigate } from "react-router-dom";
import style from "./Bottombar.module.css";
import AddIcon from "@mui/icons-material/Add";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import NewTask from "../Task/NewTask";
import SideBarMenu from "./SideBarMenu";
import { useDispatch, useSelector } from "react-redux";
import { activeBottomMenu, fabButtonDisabled, handleNewTaskDrawer } from "../../API/Reducer/menuReducer";
import { handleEBBox, handleEBoxModal } from "../../API/Reducer/userReducer";
import { clearCache } from "../../MIS/Global";
import CachedIcon from '@mui/icons-material/Cached';

const handleClearCache = () => {
  clearCache().then(() => {
   // Optionally reload the page or redirect
   window.location.reload();
 });
};

const MenuItem = ({ icon: Icon, label, isActive, onClick }) => (
  <div className={style.icons} onClick={onClick} onDoubleClick={handleClearCache}>
    <Icon className={`${style.iconsize} ${isActive ? style.active : ""}`} />
    <h5 className={isActive ? style.active : ""}>{label}</h5>
  </div>
);
const Bottombar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fabButton,activeBottomMenuStatus,newTaskDrawer } = useSelector((state) => state.menu);
  const {userStatusData} = useSelector((state) => state.user);
  const [menudrawer, setMenuDrawer] = useState(false);
  const [onsubmit, setOnSubmit] = useState(false);
  const handleClick = (item) => {
     dispatch(activeBottomMenu(item));
  };
   useEffect(() => {
      if(window.location.pathname==="/task"){
        dispatch(fabButtonDisabled(true))
      }else{
        dispatch(fabButtonDisabled(false))
      }
   }, [])
   
   
  return (
    <>
      <div className={style.bottomcon}>
         <MenuItem
          icon={HomeIcon}
          label="Home"
          isActive={activeBottomMenuStatus === "Home"}
          onClick={() =>{handleClick("Home");navigate("/home")}}                    
        />
      <MenuItem
          icon={CachedIcon}
          label="Reload"
          isActive={activeBottomMenuStatus === "Tasks"}
          onClick={() =>{handleClearCache()}}
        />
        <div className={style.icons}>
          <Fab
            onClick={(e) => {
              dispatch(handleNewTaskDrawer());        
            }}
            // color="success"
            size="large"
            disabled={fabButton}
            sx={{
              bottom: "20px",
              padding: "20px",
              backgroundColor: "#0D82C2!important",
              color: "#fff",
            }}
          >
            <AddIcon className={style.iconsize} />
          </Fab>
        </div>
        <MenuItem
          icon={SettingsIcon}
          label="Setting"
          isActive={activeBottomMenuStatus === "Setting"}
          onClick={()=>{handleClick("Setting");navigate("/usersetting")}}
          
        />
        <MenuItem
          icon={MoreHorizIcon}
          label="More"
          isActive={activeBottomMenuStatus === "More"}
          onClick={()=>{handleClick("More");setMenuDrawer(!menudrawer);}}
          
        />
        
      </div>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            // height: "80vh",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "15px",
            backgroundColor:"#FFFAE6",
            // backgroundColor:"#FFFDD0",
            
          },
        }}
        open={newTaskDrawer}
        anchor="bottom"
        onClose={(e) => {
          dispatch(handleNewTaskDrawer());
        }}
      >
        <div className={style.topcon}>
          <div>
            <span> New Task</span>
            <Button size="small" variant="contained" color="success"
             type="button" onClick={(e)=>{
              if(userStatusData?.ustatus==1 || userStatusData?.ustatus==2){
                dispatch(handleEBoxModal());
                return;
              } 
              setOnSubmit(true)
              
              }}
            >
              Save
            </Button>
          </div>
        </div>
         <div style={{ height: "70vh", overflowY: "auto",paddingTop:"10px"}}>
           <NewTask onsubmit={onsubmit} setOnSubmit={setOnSubmit}/>          
        </div>
        <div className={style.drawerclose}>
          <Fab
            onClick={(e) => {
              dispatch(handleNewTaskDrawer());
             }}
            sx={{ backgroundColor: "" }}
            size="medium"
          >
            X
          </Fab>
        </div>
      </Drawer>

      <Drawer
        anchor='left'
        open={menudrawer}
        onClose={(e) => {
          setMenuDrawer(!menudrawer);
        }}
        >
          <SideBarMenu/> 
        </Drawer>
    </>
  );
};

export default Bottombar;
