import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD, handleGetLocalStorage } from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";

  

const settingReducer = createSlice({
    name: 'setting',
    initialState: {
        settingList:{
            tasksetting:{
               defaultDays:30, 
            }
        },
        settings:[],
        settingCall:false,

    },

    reducers: {           
         setSettingList(state, action) {
            state.settingList = action.payload;
        },
        setSettings(state, action) {
            state.settings = action.payload;
        },
        setSettingCall(state, action) {
            state.settingCall = action.payload;
        },   
               
    }
});

export const { setSettingList,setSettings,setSettingCall} = settingReducer.actions;
export default settingReducer.reducer;
 

export const getSettingCall = (data) => {
     return async function actionData(dispatch,getState) {
      const {settingCall}=getState().setting;
      dispatch(setSettingCall(!settingCall));      
    };
  };

export const getSettingList = (data) => {
    return async function actionData(dispatch) {
      let url="getsetting"; let retData={};
      data={...data, uid:handleGetLocalStorage("id")||0}
      let res = await callAjax(url, data);
       if(res?.data?.length>0){
          const singleObject = res?.data?.[0];
          const keysToParse = ["task"]; // Add keys that might need JSON parsing

          keysToParse.forEach(key => {
              if (singleObject[key]) {
                  try {
                      singleObject[key] = typeof singleObject[key] === 'string' ? JSON.parse(singleObject[key]) : singleObject[key];
                  } catch (e) {
                    //   console.error(`Parsing error for key "${key}":`, e);
                    singleObject[key]= singleObject[key]
                  }
              }
          });

          delete singleObject.admin;
          dispatch(setSettings(singleObject));      
       }else{
        dispatch(setSettings([]));
       }
    };
  };

  export const readSettingDataFObject = (obj, topKey, nestedKey) => {
    if (obj[topKey]) {
        const parsedValue = obj[topKey];
        return parsedValue[nestedKey];
    }
    return undefined;
  };

  export const updateSettingValue = (topKey, nestedKey, newValue) => {
    return async function actionData(dispatch, getState) {
        const { settings } = getState().setting;        
         if (settings[topKey]) {
            let updatedSettings = { ...settings };
            let parsedValue = { ...updatedSettings[topKey] };
            parsedValue[nestedKey] = newValue;
            updatedSettings[topKey] = parsedValue;
            dispatch(setSettings(updatedSettings));
        }
    }
};

export const saveSetting = (data) => {
    return async function actionData(dispatch,getState) {
      let url="updatesetting"; let retData={};
      const {settings}=getState().setting;
        let res = await callAjax(url, settings);
      if (res.data > 0) {
        dispatch(
          setAlertData({
            type: "success",
            msg: "Setting updated successfully",
            code: 200,
          })
        );    
       } else {
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
      }
       
    };
  };

 
