import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD } from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";

  

const brandReducer = createSlice({
    name: 'brand',
    initialState: {
        brandList:[],           
        brandOption:[],
        brandListCall:false,

    },

    reducers: {           
         setBrandList(state, action) {
            state.brandList = action.payload;
        },
        setBrandOption(state, action) {
            state.brandOption = action.payload;
        },
        setBrandListCall(state,action){
          state.brandListCall=action.payload;
        }
    }
});

export const {setBrandList,setBrandOption,setBrandListCall} = brandReducer.actions;
export default brandReducer.reducer;
 

export const handleBrandListFlag = (data) => {
  return async function actionData(dispatch,getState) {
      const {brandListCall}=getState().brand; 
      dispatch(setBrandListCall(!brandListCall));     
  }
};

export const getBrands = (data) => {
    return async function actionData(dispatch,getState) {
        let res = await callAjax("getbrands",data);
        dispatch(setBrandList(res?.data));
        if(res?.data.length > 0){
         const transformedData = res?.data.map(item => ({
            id: item.id,
            label: item.brand,
          }));
          
          dispatch(setBrandOption(transformedData));
        }
        
    }
  };

  export const saveBrand = (data,setValue) => {
    let isError = 0;
    return async function actionData(dispatch, getState) {
      let url="brand";
      if (parseInt(data?.id) > 0) {
        url="brandupdate"
      }   
      let res = await callAjax(url, data);
      if (res.data > 0) {
        dispatch(
          setAlertData({
            type: "success",
            msg: "Brand saved successfully",
            code: 200,
          })
        );
        setValue('id',0);setValue('brand','');
        let data={ isorder:"asc"}
        dispatch(getBrands(data));
      }else if(res.data<= -1){
        dispatch(
            setAlertData({
              type: "error",
              msg: `"${data?.brand}" already exist`,
              code: 100,
            })
          );
      } 
      else {
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
      }
    };
  };
 
  export const editBrand = (setValue,data) => {
    setValue('id',data?.id);
    setValue('brand',data?.brand); 
  }
 
  export const deleteBrands = (data) => {
    return async function actionData(dispatch,getState) {
        let res = await callAjax("deletebrand",data);
        if (res.data > 0) {
            dispatch(
              setAlertData({
                type: "success",
                msg: "Brand deleted successfully",
                code: 200,
              })
            );
            let data={ isorder:"asc"}
            dispatch(getBrands(data));
          }else if(res.data<= -1){
            dispatch(
                setAlertData({
                  type: "error",
                  msg: `You can't delete "${data?.brand}" already used into task transaction`,
                  code: 100,
                })
              );
          } 
          else {
            dispatch(
              setAlertData({
                type: "error",
                msg: "Something went wrong!Please try again",
                code: 100,
              })
            );
          }
        
    }
  };
 
