import React from 'react'
import Grid from '@mui/material/Grid'
import LandingLayout from '../Layout/LandingLayout'
import './TermsCondition.scss'

const PrivacyPolicy = () => {
  return (
    <>
      <LandingLayout bgcolor={"#fff"}>
      <Grid container spacing={0}>
       <Grid item xs={12} md={12} className="tc-main-heading">
         <h1>Privacy Policy</h1>
       </Grid>
       <Grid item xs={12} md={12} p={2}>
         <p>
          D2 Super is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use D2 Super.
         </p>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Information We Collect</h2>
         <p>We may collect the following types of information from you:</p>
         <li><b>Personal Information:</b> This includes your name, email address, phone number, and other information you provide when creating an account or contacting us for support.</li>
         <li><b>Payment Information:</b> If you subscribe to premium features, we may collect payment details, including billing address, payment method, and transaction history. For offline payments, relevant details will be collected as part of the payment process.</li>
         <li><b>Usage Data:</b> We collect information about how you interact with the App, such as task creation, login times, and usage patterns.</li>
         <li><b>Device Information:</b> We may collect data about the device you use to access the App, including IP address, operating system, and browser type.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>How We Use Your Information</h2>         
         <p>We use the information we collect to:</p>
         <li>Provide, operate, and maintain the App.</li>
         <li>Process payments and manage your subscription, including offline payments.</li>
         <li>Improve the App, develop new features, and enhance your user experience.</li>
         <li>Communicate with you, including sending updates, notifications, and promotional offers.</li>
         <li>Ensure compliance with legal obligations and resolve disputes.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Data Sharing and Disclosure</h2>
         <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with</p>
         <li><b>Service Providers:</b> Third-party vendors who assist us in operating the App, processing payments, and providing customer support.</li>
         <li><b>Legal Requirements:</b> If required by law or in response to legal proceedings, we may disclose your information to relevant authorities.</li>
         <li><b>Business Transfers:</b> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner as part of the transaction.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Security of Your Information</h2>
         <li>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Data Retention</h2>
         <li>We retain your personal information for as long as necessary to provide you with the services, comply with legal obligations, resolve disputes, and enforce our agreements. Once your data is no longer needed, we will securely delete or anonymize it.</li>        
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Your Rights and Choices</h2>
         <p>You have the right to:</p>
         <li><b>Access:</b> Request a copy of the personal information we hold about you.</li>
         <li><b>Correction:</b> Update or correct any inaccurate or incomplete information.</li>
         <li><b>Deletion:</b> Request the deletion of your personal information, subject to certain legal exceptions.</li>
         <li><b>Opt-Out:</b> Unsubscribe from marketing communications at any time.</li>
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Cookies and Tracking Technologies</h2>
         <li>We may use cookies and similar tracking technologies to enhance your experience on the App. Cookies are small data files stored on your device that help us understand your preferences and usage patterns. You can manage your cookie preferences through your browser settings.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Children's Privacy</h2>
         <li>The App is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have inadvertently collected such information, we will take steps to delete it.</li>         
       </Grid>
       <Grid item xs={12} md={12} className="tc-info">
         <h2>Changes to This Privacy Policy</h2>
         <li>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the date of the latest revision will be indicated at the top. Your continued use of the App after any changes constitutes your acceptance of the updated policy.</li>         
       </Grid>
     </Grid>
      </LandingLayout>  
   
    </>
  )
}

export default PrivacyPolicy