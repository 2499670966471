import React from "react";
import FixedLayout from "../Layout/FixedLayout";
import Bottombar from "../Appbar/Bottombar";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import TaskSettingComp from "./TaskSettingComp";
import './SettingPage.scss'
import { useDispatch } from "react-redux";
import { saveSetting } from "../../API/Reducer/settingReducer";
import AlertComp from "../AlertMessageCom/AlertComp";

const SettingPage = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const accordionData = [
    {
      id: 1,
      summary: "Task Setting",
      details: (<><TaskSettingComp/></>),
      actions: null,
      defaultExpanded: false,
      icon:(<><AssignmentIcon color="info" /></>)
    },
  ];
  const headerContent = (
    <>
      <Grid container spacing={1} p={1}>
        <Grid
          item
          xs={1}
          className="bckarwcon"
          onClick={(e) => {
            navigate("/home");
          }}
        >
          <ArrowBackIcon className="bckarow" />
        </Grid>
        <Grid item xs={3} className="hading">
          Settings
        </Grid>
        <Grid item xs={8} sx={{display:"flex",justifyContent:"end"}}>
         <Button type="button" color="success" variant="contained"
          onClick={(e)=>{dispatch(saveSetting())}}
         >Submit</Button>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
      <AlertComp/>
      <FixedLayout header={headerContent} footer={<Bottombar />}>
         {accordionData.map((accordion) => (
          <Accordion
            key={accordion.id}
            defaultExpanded={accordion.defaultExpanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${accordion.id}-content`}
              id={`panel${accordion.id}-header`}              
            >
              <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
              <span>{accordion?.icon}</span><span>{accordion.summary}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails>{accordion.details}</AccordionDetails>
          </Accordion>
        ))}
       </FixedLayout>
    </>
  );
};

export default SettingPage;
