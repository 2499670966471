import React, { useEffect, useState } from "react";
import AdminLayout from "../Layout/AdminLayout";
import { Grid, Paper, TextField, Button, InputAdornment, TableContainer, Table, TableCell, TableHead, TableRow, TableBody, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AlertComp from "../AlertMessageCom/AlertComp";
import { handleMaxLengthInput } from "../../MIS/Global";
import SearchIcon from '@mui/icons-material/Search';
import { deleteBrands, editBrand, getBrands, saveBrand } from "../../API/Reducer/brandReducer";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";
import { setConfirmDialogData } from "../../API/Reducer/confirmDialogReducer";

const AdminBrandMaster = () => {
  const dispatch = useDispatch();
  const { brandList } = useSelector((state) => state.brand);
  const {type,resans} = useSelector(state => state.confirm);
  const [searchInput, setSearchInput] = useState('');
  const [id, setId] = useState(0);
  const [brand, setBrand] = useState('');
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      brand: "",
      isactive: 0,
    },
  });

  const onSubmit = (data) => {
    dispatch(saveBrand(data,setValue));
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const getData=()=>{
    let data={ isorder:"asc"}
    dispatch(getBrands(data));
  }

  useEffect(() => {
    if(type==='del' && resans===true){
        dispatch(deleteBrands({id:id,brand:brand}));
        setId(0);
        setBrand('');
    }
  }, [resans])

  useEffect(() => {
   getData();
  }, [])

  const filteredBillData = brandList.filter((element) => {
    const searchStr = searchInput.toLowerCase();
    return (
      element?.brand.toLowerCase().includes(searchStr)
    );
  }); 
  
  return (
    <>
      <AlertComp/>
      <ReduxConfirmationDialog/>

      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <AdminLayout>
          <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <div style={{padding:"10px"}}>
             <h2>Brand</h2>
            </div>
          </Grid>
            <Grid item md={6} xs={12}>
              <Paper sx={{padding:"10px"}}>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      size="small"
                      fullWidth
                      label="Brand Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        onInput: (event) => handleMaxLengthInput(event, 100),
                      }}
                      {...register("brand", {
                        required: "Brand Name is required",
                        minLength: {
                          value: 3,
                          message:
                            "Brand Name must be at least 3 characters long",
                        },
                      })}
                      error={!!errors.brand}
                      helperText={errors.brand?.message}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sx={{display:"flex", justifyContent:"end",alignItems:"center"}}>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
            <Paper sx={{padding:"10px"}}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} sx={{display:"flex",justifyContent:"end",alignItems:'center'}}>
                <TextField
                      size="small"
                      label="Search"
                      placeholder="search..."
                      InputProps={{
                        onInput: (event) => handleMaxLengthInput(event, 100),
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon/>
                          </InputAdornment>
                        ),
                      }}   
                      onChange={handleSearchInputChange}
                    />    
                </Grid>
                <Grid item xs={12} md={12}>
                <TableContainer sx={{ maxHeight: "100vh",overflowY:"scroll" }}>
                  <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Brand Name
                      </TableCell>
                      <TableCell sx={{textAlign:"end"}}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                  {filteredBillData.map((element, index) => {
                  let bgcolor = "#fff";
                  if (index % 2 == 0) {
                    bgcolor = "aliceblue";
                  }
                  return (
                    <TableRow
                    key={element.id}
                    sx={{backgroundColor:bgcolor}}
                    >
                    
                    <TableCell>
                        {element?.brand}
                      </TableCell>
                      <TableCell sx={{display:"flex", gap:"10px",justifyContent:"end",alignItems:"center"}}>
                         <Chip size="small" label="Edit" color="secondary" onClick={(e)=>{editBrand(setValue,element)}}/>
                         <Chip size="small" label="Del" color="error"
                          onClick={(e)=>{
                            dispatch(setConfirmDialogData({
                            msg:"Do you want to delete this record?",yeslbl:"Yes",nolbl:"No",heading:"Delete Confirmation",
                            openclose:true,type:"del"}));
                            setId(element?.id);
                            setBrand(element?.brand);
                          }}
                         />
                      </TableCell>
                    </TableRow>
                  );
                })}  
              </TableBody>

                  </Table>
                </TableContainer>
                </Grid>
              </Grid>
              </Paper>
          </Grid>
          </Grid>
        </AdminLayout>
      </form>
    </>
  );
};

export default AdminBrandMaster;
