import { configureStore } from "@reduxjs/toolkit";
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from "../Reducer/alertReducer";
import errorReducer from "../Reducer/errorReducer";
import userReducer from "../Reducer/userReducer";
import menuReducer from "../Reducer/menuReducer";
import taskReducer from "../Reducer/taskReducer";
import brandReducer from "../Reducer/brandReducer";
import settingReducer from "../Reducer/settingReducer";

const store = configureStore({
    reducer: {
        confirm:confirmDialogReducer,
        alert:alertReducer,
        error:errorReducer,
        user:userReducer,
        task:taskReducer,
        menu:menuReducer,
        brand:brandReducer,
        setting:settingReducer,
    }
});

export default store;