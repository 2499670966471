/* eslint-disable react/jsx-no-undef */
import React from "react";
import "./index.css";
import store from "../src/API/Store/store";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import HomePage from "./Components/HomePage/HomePage";
import ProductList from "./Components/Products/ProductList";
import DemoPage from "./Components/HomePage/DemoPage";
import TaskPage from "./Components/Task/TaskPage";
import TaskListPage from "./Components/Task/TaskListPage";
import LandingPage from "./Components/Login/LandingPage";
import Test from "./Components/Fields/Test";
import LandingOne from "./Components/LandingPages/LandingOne";
import AdminBrandMaster from "./Components/BrandMaster/AdminBrandMaster";
import AdminDashboard from "./Components/Dashboard/AdminDashboard";
import AdminUserList from "./Components/User/AdminUserList";
import UserLogin from "./Components/Login/UserLogin";
import UserSignUp from "./Components/Login/UserSignUp";
import SettingPage from "./Components/Setting/SettingPage";
import UserForgotPassword from "./Components/Login/UserForgotPassword";
import NewUserList from "./Components/User/NewUserList";
import TermsCondition from "./Components/TC/TermsCondition";
import PrivacyPolicy from "./Components/TC/PrivacyPolicy";
import ExpirePage from "./Components/Login/ExpirePage";
 

const root = document.getElementById("root");
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/test" element={<ExpirePage/>}/>
          <Route path="/" element={<LandingOne/>}/>
          <Route path="/termscondition" element={<TermsCondition/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>

          <Route path="/splash" element={<LandingPage/>}/>
          <Route path="/login" element={<UserLogin/>}/>
          <Route path="/signup" element={<UserSignUp/>}/>
          <Route path="/expire" element={<ExpirePage/>}/>
          <Route path="/userforgotpassword" element={<UserForgotPassword/>}/>
          <Route path="/home" element={<HomePage/>} />
          <Route path="/task/:id?" element={<TaskPage/>} />
          <Route path="/tasklist" element={<TaskListPage/>} />
          <Route path="/usersetting" element={<SettingPage/>}/>
          <Route path="/teamlist" element={<NewUserList/>}/>
          <Route path="/productlist" element={<ProductList />}/> 
          <Route path="/demo" element={<DemoPage/>} />

          <Route path="/admin" element={<AdminDashboard/>}/>
          <Route path="/admin/brand" element={<AdminBrandMaster/>}/>
          <Route path="/admin/userlist" element={<AdminUserList/>}/>
                         
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  root
);

// s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
