import { Box, Divider, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleGetLocalStorage, handleMaxLengthInput } from "../../MIS/Global";
import Switch from "@mui/material/Switch";
import BorderedDivWithLabel from "./BorderedDivWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { readSettingDataFObject, updateSettingValue } from "../../API/Reducer/settingReducer";
import defSetting from '../../MIS/Settings.json';
import ToggleSwitch from "./ToggleSwitch";

const TaskSettingComp = () => {
  const dispatch=useDispatch();
  const {settings} = useSelector((state) => state.setting);

 
  const [switchOn, setSwitchOn] = useState(false);

  const handleToggle = () => {
    setSwitchOn(!switchOn);
    dispatch(updateSettingValue("task","isreturn",!switchOn))
  };
  
  useEffect(() => {
    let ret=readSettingDataFObject(settings,'task','isreturn');
    let retType = typeof ret;
    if(retType=="string"){
      if(ret=="false"){
        setSwitchOn(false);
      }else{
        setSwitchOn(true);
      }
    }else{
      if(ret==false){
        setSwitchOn(false);
      }else{
        setSwitchOn(true);
      }
    }     
  },[settings])
  

   
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} sx={{display:"none"}}>
           <TextField
            type="number"
            size="small"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }} 
            label="Days to load"
            inputProps={{ onInput: (event) => handleMaxLengthInput(event, 3) }}
            value={readSettingDataFObject(settings,"task","defulatloaddays") || defSetting?.task?.defulatloaddays}
            onChange={(e)=>{dispatch(updateSettingValue("task","defulatloaddays",e.target.value))}}

          />
        </Grid>
        <Grid item xs={12} md={12}
         sx={{display:handleGetLocalStorage('urole')==0?"block":"none"}}
        >
          <TextField
            type="number"
            size="small"
            variant="outlined"
            InputLabelProps={{ shrink: true }} 
            fullWidth
            label="Pending Days"
            inputProps={{ onInput: (event) => handleMaxLengthInput(event, 3) }}
            value={readSettingDataFObject(settings,"task","pendingdays") || defSetting?.task?.pendingdays}
            onChange={(e)=>{dispatch(updateSettingValue("task","pendingdays",e.target.value))}}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}>
          <BorderedDivWithLabel label="Return Item">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>Sim, Sim Tray, Cover, Memory Card</span>            
              <ToggleSwitch
                id="example-switch"
                checked={switchOn}
                onChange={handleToggle}
                optionLabels={['On', 'Off']}
                color="#6200ee"
              />
            </div>
          </BorderedDivWithLabel>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskSettingComp;
