import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCntByIsstatus, getTaskCount } from "../../API/Reducer/taskReducer";
import styles from "./TaskTab.module.css";
import { homeMenuSelect } from "../../API/Reducer/menuReducer";
const TaskTab = () => {
  const dispatch = useDispatch();
  const { activeItem } = useSelector((state) => state.menu);
  const { taskCount } = useSelector((state) => state.task);
  const [activeButton, setActiveButton] = useState(null);

  const handleToggle = (buttonIndex) => {
    if(buttonIndex===1){
        dispatch(homeMenuSelect("task"))
    }else if(buttonIndex===2){
        dispatch(homeMenuSelect("pending"))
    }else{
        dispatch(homeMenuSelect("complete"))
    }
    setActiveButton(buttonIndex);
  };
  useEffect(() => {
    handleToggle(1);
    dispatch(getTaskCount());
  }, []);
  return (
    <>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleWrapper}>
          <div
            className={`${styles.toggleButton} ${
              activeButton === 1 ? styles.active : ""
            }`}
            onClick={() => handleToggle(1)}
          >
            TASK <br></br>{dispatch(getCntByIsstatus(taskCount,0))}
          </div>
          <div
            className={`${styles.toggleButton} ${
              activeButton === 2 ? styles.active : ""
            }`}
            onClick={() => handleToggle(2)}
          >
            PENDING <br></br>{dispatch(getCntByIsstatus(taskCount,1))}
          </div>
          <div
            className={`${styles.toggleButton} ${
              activeButton === 3 ? styles.active : ""
            }`}
            onClick={() => handleToggle(3)}
          >
            COMPLETE <br></br>{dispatch(getCntByIsstatus(taskCount,2))}
          </div>
          <div
            className={styles.switch}
            style={{ transform: `translateX(${(activeButton - 1) * 100}%)` }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default TaskTab;
