import React from 'react';
import './ToggleSwitch.scss';

const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  optionLabels = ['On', 'Off'],
  small,
  disabled,
  color = '#4cd964',
}) => {
  return (
    <div className={`toggle-switch ${small ? 'small' : ''}`}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id || name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {id || name ? (
        <label
          className="toggle-switch-label"
          htmlFor={id || name}
          style={{ background: checked ? color : undefined }}
        >
          <span className="toggle-switch-inner" data-yes={optionLabels[0]} data-no={optionLabels[1]} />
          <span className="toggle-switch-switch" />
        </label>
      ) : null}
    </div>
  );
};

export default ToggleSwitch;
