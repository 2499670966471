import React, { useEffect, useState, useCallback, Suspense, lazy } from "react";
import style from "./TaskCard.module.css";
import { Grid, Paper, IconButton, Box, Modal, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { formatUTCDate, getImage } from "../../MIS/Global";
import img from "../../Assets/noimage.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteTask, saveCompletedTask } from "../../API/Reducer/taskReducer";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import { setConfirmDialogData } from "../../API/Reducer/confirmDialogReducer";
import ReduxConfirmationDialog from "../ConfirmationDialog/ReduxConfirmationDialog";
import { useNavigate } from "react-router-dom";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
const LazyTaskImageSmall = lazy(() => import("./LazyTaskImageSmall"));

const TaskCard = ({ element }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, resans } = useSelector((state) => state.confirm);

  const [open, setOpen] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [singleImage, setSingleImage] = useState(img);
  const [taskid, setTaskId] = useState(0);
  const [delimages, setDelImages] = useState([]);

  const handleOpen = (images) => {
    let imgArray;
    try {
      imgArray = JSON.parse(images);
    } catch (error) {
       imgArray = []; // Provide a fallback value, such as an empty array
    }
    setImgList(imgArray);
    setOpen(true);
    if (imgArray.length > 0) {
      // setSingleImage(getImage("public/" + imgArray?.[0]?.imgpath));
      setSingleImage(getImage(imgArray?.[0]?.imgpath));
    } else {
      setSingleImage(img);
    }
  };
  const handleClose = () => setOpen(false);

  const handleDueAmount = (es = 0, adv = 0) => {
    let calc = Number(es) - Number(adv);
    calc = Number(calc).toFixed(2);
    return calc;
  };
  const imgPath = (images) => {
    let imgArray;
    try {
      imgArray = JSON.parse(images);
    } catch (error) {
       imgArray = []; // Provide a fallback value, such as an empty array
    }
    if (imgArray.length > 0) {
      // return getImage("public/" + imgArray?.[0]?.imgpath);
      return getImage(imgArray?.[0]?.imgpath);
    } else {
      return img;
    }
  };
  const getFilterImage = (id) => {
    const filterData = imgList.filter((item) => item.id === id);
    if (filterData.length > 0) {
      // let imgPath = getImage("public/" + filterData?.[0]?.imgpath);
      let imgPath = getImage(filterData?.[0]?.imgpath);
      setSingleImage(imgPath);
    } else {
      setSingleImage(img);
    }
  };

  const handleDeleteImages = (images) => {
    let imgArray;
    try {
      imgArray = JSON.parse(images);
    } catch (error) {
       imgArray = []; // Provide a fallback value, such as an empty array
    }
    setDelImages(imgArray);
  };

  useEffect(() => {
    if (type === "taskdel" && resans === true) {
      dispatch(deleteTask({ id: taskid, imgs: delimages }));
    }else if(type==="taskpending" && resans===true)
    {
      dispatch(
        saveCompletedTask({
          id: taskid,
          isstatus: 1          
        })
      );
    }
  }, [resans]);

  return (
    <>
      <ReduxConfirmationDialog />
      <div
        className={style.main}
        onClick={(e) => {
          e.stopPropagation();
          navigate("/task/" + element?.id);
        }}
      >
        <div className={style.con}>
          <h5>
            {element?.pname || "Client Name"}-{element?.pmob || "9245XXXXXX"}
          </h5>
          <h5 className={style.num}>{element?.taskid || "01"}</h5>
        </div>
        <div className={style.con}>
          <h4 className={style.issueinfo}>
            {element?.problem ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"}
          </h4>
        </div>
        <div className={style.con}>
          <h5>
            {formatUTCDate(new Date(element?.edate || new Date()), "dmyhm")}
          </h5>
          <h5>
            {element?.brand || "Samsung"} - {element?.model || "Galaxy M14"}
          </h5>
        </div>
        <div className={style.con}>
          <h5 className={style.issueinfo}>Estimate x Advance x Due</h5>
          <h5 className={style.issueinfo}>
            <span>
              {Number(element?.estimate || 0).toFixed(2)}&nbsp;X&nbsp;
            </span>
            <span>{Number(element?.advance || 0).toFixed(2)}&nbsp;X&nbsp;</span>
            <span
              className={
                handleDueAmount(element?.estimate, element?.advance) > 0
                  ? style?.dueAmount
                  : ""
              }
            >
              {handleDueAmount(element?.estimate, element?.advance)}
            </span>
          </h5>
        </div>
        <div className={style.con}>
          <div
            className={style.imgcon}
            onClick={(e) => {
              e.stopPropagation();
              handleOpen(element?.imgpaths);
            }}
          >
            <Suspense fallback={<CircularProgress />}>
              <LazyTaskImageSmall src={imgPath(element?.imgpaths)} />
            </Suspense>
          </div>
          <div
            className={style.delcon}
            style={{ display: `${element?.isstatus == 2 ? "none" : ""}` }}
          >
            <Chip
              size="small"
              color="error"
              label="DEL"
              sx={{ display: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  setConfirmDialogData({
                    msg: "Do you want to delete this record?",
                    yeslbl: "Yes",
                    nolbl: "No",
                    heading: "Delete Confirmation",
                    openclose: true,
                    type: "taskdel",
                  })
                );
                setTaskId(element?.id);
                handleDeleteImages(element?.imgpaths);
                
              }}
              icon={<DeleteIcon sx={{ fontSize: "12px" }} />}
            ></Chip>
            <Chip
              size="small"
              color="success"
              label="Pending"
              style={{ display: `${element?.isstatus == 1 ? "none" : ""}` }}
              onClick={(e) => {
                e.stopPropagation();
                  dispatch(
                     saveCompletedTask({
                      id: element?.id,
                      isstatus: 1          
                    })
                  );            
                // setTaskId(element?.id);
              }}
              icon={<LocalParkingIcon sx={{ fontSize: "12px" }} />}
            ></Chip>
            <Chip
              size="small"
              color="primary"
              label="Done"
              icon={<ThumbUpOffAltIcon sx={{ fontSize: "12px" }} />}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  saveCompletedTask({
                    id: element?.id,
                    isstatus: 2,
                    advance: element?.estimate,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <div className={style.modalcon}>
          <div className={style.mcon}>
            <h4>Photos</h4>
            <div
              className={style.times}
              onClick={() => {
                handleClose();
              }}
            >
              &times;
            </div>
          </div>
          <div className={style.mcon}>
            <div className={style.mimgcon}>
              <Zoom>
                <Suspense fallback={<CircularProgress />}>
                  <LazyTaskImageSmall src={singleImage} />
                </Suspense>
              </Zoom>
            </div>
          </div>
          <div className={style.mcon1}>
            {imgList.map((element, index) => {
              return (
                <Suspense fallback={<CircularProgress />}>
                  <div
                    className={style.imgcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      getFilterImage(element?.id);
                    }}
                  >
                    <LazyTaskImageSmall src={getImage(element?.imgpath)} />
                  </div>
                </Suspense>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default TaskCard;
