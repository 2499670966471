import React, { useRef, useState,useLayoutEffect  } from "react";
import "./UserSignUp.scss";
import { useForm } from "react-hook-form";
import AlertComp from "../AlertMessageCom/AlertComp";
import { useDispatch } from "react-redux";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, InputAdornment, TextField } from "@mui/material";
import { handleGetLocalStorage, handleMaxLengthInput } from "../../MIS/Global";
import logo from "../../Assets/d2logo.png";
import { useNavigate } from "react-router-dom";
import { signUser } from "../../API/Reducer/userReducer";
const UserLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ispaswdstatus, setIsPaswdStatus] = useState(false);
  const textFieldRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      umob: "",
      paswd: "",
    },
  });
  const onSubmit = (data) => {
    dispatch(signUser(data));
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  const handlePaswdChange = () => {
    setIsPaswdStatus(!ispaswdstatus);
  };

  useLayoutEffect(() => {
    let id=handleGetLocalStorage('id');
    if (id>0) {
      window.location.href="/home";
    }
  }, []);
  return (
    <>
      <AlertComp />
      <div className="userreg-main">
        <div className="userreg-con1">
          <svg
            width="834"
            height="494"
            viewBox="0 0 834 494"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="footerwavesvg"
          >
            <path
              d="M0 0H834V494L323.919 297.098C228.557 260.286 118.085 295.492 68.758 378.414L0 494V0Z"
              fill="#FF4B2B"
            />
            <path
              d="M377.597 253.148C317.925 231.227 295.947 164.604 332.477 116.378L420.628 0.000261731L834 0L834 420.815L377.597 253.148Z"
              fill="#FF765E"
            />
            <path
              d="M646.104 257.071C614.926 244.182 606.735 207.296 629.964 184.392L834 0V338.482L646.104 257.071Z"
              fill="#FF9E8D"
            />
            <path
              d="M43.5875 172.538C74.7628 184.711 88.7745 217.895 74.5833 245.946L5.58727e-07 393.37L1.19785e-05 155.519L43.5875 172.538Z"
              fill="#FF9E8D"
            />
          </svg>

          <div id="head">
            Hello Sign In
          </div>
        </div>
        <div className="userreg-content">
         <div className="userlogin-imgcon">
             <img src={logo}/>
          </div>
          <form
            className="userreg-form"
            onSubmit={handleSubmit(onSubmit, onError)}
            autoComplete="off"
          >
            <TextField
              inputRef={textFieldRef}
              type="number"
              size="small"
              fullWidth
              label="Mobile Number"
              variant="standard"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 10),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <StayCurrentPortraitIcon />
                  </InputAdornment>
                ),
              }}
              {...register("umob", {
                required: "Mobile Number is required",
                minLength: {
                  value: 10,
                  message: "Mobile Number must be at least 10 digit long",
                },
              })}
              error={!!errors.umob}
              helperText={errors.umob?.message}
            />

            <TextField
              type={ispaswdstatus ? "text" : "password"}
              size="small"
              fullWidth
              label="Password"
              variant="standard"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 20),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                    onClick={handlePaswdChange}
                  >
                    {ispaswdstatus ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              {...register("paswd", {
                required: "Password is required",
              })}
              error={!!errors.paswd}
              helperText={errors.paswd?.message}
            />
            <span className="fgpaswd" onClick={()=>{navigate("/userforgotpassword")}}>Forgot Password?</span>
            <Button
              type="submit"
              sx={{ borderRadius: "25px", fontSize: "20px" }}
              color="error"
              size="large"
              variant="contained"
             >
              Sign In
            </Button>
          </form>
        </div>
        <div className="userreg-footer">
          <span
            onClick={() => {
              navigate("/signup");
            }}
          >
            Already have an account? <a className="userreg-signup">Sign Up</a>
          </span>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
