import React, { useEffect, useState } from 'react'
import './NewUserList.scss'
import { Chip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { deleteTeamMember } from '../../API/Reducer/userReducer';
import { setConfirmDialogData } from '../../API/Reducer/confirmDialogReducer';
import ReduxConfirmationDialog from '../ConfirmationDialog/ReduxConfirmationDialog';
const NewUserCard = ({element}) => {
  const dispatch = useDispatch();
  const {type,resans} = useSelector(state => state.confirm);

  const [umob, setUmob] = useState("");

  useEffect(() => {
    if(type==='teamdel' && resans===true){
       dispatch(deleteTeamMember({umob:umob}));      
    }
  }, [resans])

  return (
    <>
    <ReduxConfirmationDialog/>
    <div className='team-main'>
      <div className='row'>
        <h4>{element?.uname}</h4>
        <h4>{element?.umob}</h4>
      </div>
      <div className='row'>
        <h4>{element?.uemail}</h4>        
      </div>
      <div className='row'>
        <div></div>
        <Chip color="error" label="DEL" size='small'
          onClick={(e)=>{
            e.stopPropagation();
            dispatch(setConfirmDialogData({
              msg:"Do you want to delete this record?",yeslbl:"Yes",nolbl:"No",heading:"Delete Confirmation",
              openclose:true,type:"teamdel"}));
              setUmob(element?.umob);
          }}
        />
      </div>
    </div>
    </>
  )
}

export default NewUserCard