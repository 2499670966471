import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  Typography,
} from "@mui/material";
import SimpleInput from "../Fields/SimpleInput";
import NewImagesUpload from "../GlobalComponent/NewImagesUpload";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomSelect from "../GlobalComponent/CustomSelect";
import {
  formatUTCDate,
  handleFocus,
  handleGetLocalStorage,
  handleMaxLengthInput,
  handleRemoveKeys,
} from "../../MIS/Global";
import { useDispatch, useSelector } from "react-redux";
import {
  returnEquipmentSet,
  saveTask,
  setReturnEqup,
  setTaskList,
} from "../../API/Reducer/taskReducer";
import AlertComp from "../AlertMessageCom/AlertComp";
import { getBrands, handleBrandListFlag } from "../../API/Reducer/brandReducer";
import BorderedDivWithLabel from "../Setting/BorderedDivWithLabel";
import { readSettingDataFObject } from "../../API/Reducer/settingReducer";
import { useNavigate, useParams } from "react-router-dom";
import { getTaskList } from "../../API/Reducer/taskReducer";
const NewTask = ({ onsubmit, setOnSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.setting);
  const { returnEqup, taskList } = useSelector((state) => state.task);
  const { brandOption, brandListCall } = useSelector((state) => state.brand);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [photos, setPhotos] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const { id } = useParams();
  const [switchOn, setSwitchOn] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      taskid: 0,
      pname: "",
      pmob: "",
      brand: selectedBrand?.label || "",
      model: "",
      problem: "",
      retsim: 0,
      retcover: 0,
      retmc: 0,
      retsimtray: 0,
      estimate: 0,
      advance: 0,
      isstatus: 0,
      paswd: "",
      remark: "",
      imgpaths: {},
      edate: formatUTCDate(new Date(), "ymdhm"),
      duedate: formatUTCDate(new Date(), "ymdhm", 2),
      cmpid: handleGetLocalStorage("cmpid") || 0,
      uid: handleGetLocalStorage("id") || 0,
    },
  });

  const onSubmit = async (data) => {
    if (getValues("isstatus") !== 0) {
      return;
    }
    if (selectedBrand?.label == "" || selectedBrand?.label == undefined) {
      return;
    }
    if (photos.length > 4) {
      return;
    }
    let subData = {
      ...data,
      brand: selectedBrand?.label,
      files: photos,
      edate: formatUTCDate(new Date(), "ymdhm"),
    };
    let whrdata = {
      isstatus: 0,
      limit: 10,
      page: 1,
      isorder: "desc",
    };
    if (id > 0) {
      const keysToRemove = [
        "taskid",
        "edate",
        "duedate",
        "imgpaths",
        "cmpid",
        "token",
      ];
      const filteredData = handleRemoveKeys(subData, keysToRemove);
      subData = filteredData;
    }
    dispatch(saveTask(subData, whrdata, reset, navigate));

    dispatch(
      setReturnEqup({
        retsim: 0,
        retcover: 0,
        retmc: 0,
        retsimtray: 0,
      })
    );

    // console.log(subData);
  };
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  useEffect(() => {
    if (onsubmit) {
      if (selectedBrand?.label == "" || selectedBrand?.label == undefined) {
        setSelectedBrand({ ...selectedBrand, isselect: false });
      }
      handleSubmit(onSubmit, onError)();
      setOnSubmit(false);
    }
  }, [onsubmit, handleSubmit, onSubmit, onError]);

  useEffect(() => {
    if (brandListCall === false) {
      dispatch(getBrands({ isorder: "asc" }));
      dispatch(handleBrandListFlag());
    }
  }, [brandListCall]);

  useEffect(() => {
    if (id > 0) {
      dispatch(getTaskList({ id: id }));
    }
  }, [id]);


  useEffect(() => {
    if (taskList.length > 0 && id > 0) {
      setValue("id", taskList?.[0]?.id);
      setValue("pname", taskList?.[0]?.pname);
      setValue("pmob", taskList?.[0]?.pmob);
      setValue("model", taskList?.[0]?.model);
      setValue("brand", taskList?.[0]?.brand);
      const activeUsers = brandOption.filter(
        (user) => user?.label === taskList?.[0]?.brand
      );
      setSelectedBrand({ ...activeUsers?.[0], isselect: true });
      setValue("problem", taskList?.[0]?.problem);
      setValue("remark", taskList?.[0]?.remark);
      setValue("estimate", taskList?.[0]?.estimate);
      setValue("advance", taskList?.[0]?.advance);
      setValue("paswd", taskList?.[0]?.paswd);
      setValue("isstatus", taskList?.[0]?.isstatus);

      setValue("retsim", taskList?.[0]?.retsim);
      setValue("retcover", taskList?.[0]?.retcover);
      setValue("retmc", taskList?.[0]?.retmc);
      setValue("retsimtray", taskList?.[0]?.retsimtray);
      dispatch(
        setReturnEqup({
          retsim: taskList?.[0]?.retsim,
          retcover: taskList?.[0]?.retcover,
          retmc: taskList?.[0]?.retmc,
          retsimtray: taskList?.[0]?.retsimtray,
        })
      );
      setEditImages(JSON.parse(taskList?.[0]?.imgpaths));
    } else {
      dispatch(
        setReturnEqup({
          retsim: 0,
          retcover: 0,
          retmc: 0,
          retsimtray: 0,
        })
      );
      setEditImages([]);
      reset();
    }
  }, [taskList]);

  const triggerFileUpload = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "uploadFile" })
      );
    }
  };

  useEffect(() => {
    triggerFileUpload();
  }, []);

  useEffect(() => {
    let ret=readSettingDataFObject(settings,'task','isreturn');
    let retType = typeof ret;
    if(retType=="string"){
      if(ret=="false"){
        setSwitchOn(false);
      }else{
        setSwitchOn(true);
      }
    }else{
      if(ret==false){
        setSwitchOn(false);
      }else{
        setSwitchOn(true);
      }
    }     
  },[settings])
  return (
    <>
      <AlertComp />      
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Party Name"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 150),
              }}
              {...register("pname", {
                required: "Party Name is required",
                minLength: {
                  value: 3,
                  message: "Party Name must be at least 3 characters long",
                },
              })}
              error={!!errors.pname}
              helperText={errors.pname?.message}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Mobile Number"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 10),
              }}
              {...register("pmob", {
                required: "Mobile Number is required",
                minLength: {
                  value: 10,
                  message: "Mobile Number must be at least 10 digits",
                },
              })}
              error={!!errors.pmob}
              helperText={errors.pmob?.message}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              options={brandOption}
              selectText={`${selectedBrand?.label || "Select an Brand"}`}
              setSelectedValue={setSelectedBrand}
            />
            {selectedBrand?.isselect === false && (
              <Typography color="error" variant="subtitle2">
                Brand Name is required{" "}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Model"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 100),
              }}
              {...register("model", {
                required: "Model Name is required",
              })}
              error={!!errors.model}
              helperText={errors.model?.message}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              multiline
              label="Problem"
              InputLabelProps={{ shrink: true }}
              {...register("problem", {
                required: "Problem is required",
              })}
              error={!!errors.problem}
              helperText={errors.problem?.message}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display:
                switchOn == false
                  ? "none"
                  : "block",
            }}
          >
            <BorderedDivWithLabel label="Return Item">
              <FormGroup row>
                <FormControlLabel
                  disabled={getValues("isstatus") == 2}
                  control={
                    <Checkbox
                      name="retsim"
                      checked={returnEqup?.retsim == 1 ? true : false}
                      onChange={(e) => {
                        dispatch(returnEquipmentSet(e, setValue));
                      }}
                    />
                  }
                  label="Sim Card"
                />
                <FormControlLabel
                  disabled={getValues("isstatus") == 2}
                  control={
                    <Checkbox
                      name="retsimtray"
                      checked={returnEqup?.retsimtray == 1 ? true : false}
                      onChange={(e) => {
                        dispatch(returnEquipmentSet(e, setValue));
                      }}
                    />
                  }
                  label="Sim Tray"
                />
                <FormControlLabel
                  disabled={getValues("isstatus") == 2}
                  control={
                    <Checkbox
                      name="retcover"
                      checked={returnEqup?.retcover == 1 ? true : false}
                      onChange={(e) => {
                        dispatch(returnEquipmentSet(e, setValue));
                      }}
                    />
                  }
                  label="Cover"
                />
                <FormControlLabel
                  disabled={getValues("isstatus") == 2}
                  control={
                    <Checkbox
                      name="retmc"
                      checked={returnEqup?.retmc == 1 ? true : false}
                      onChange={(e) => {
                        dispatch(returnEquipmentSet(e, setValue));
                      }}
                    />
                  }
                  label="Memory Card"
                />
              </FormGroup>
            </BorderedDivWithLabel>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Estimate"
              InputLabelProps={{ shrink: true }}
              {...register("estimate", {
                required: "Estimate is required",
                validate: (value) =>
                  value > 0 || "Estimate must be greater than 0",
              })}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 15),
                onFocus: handleFocus,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.estimate}
              helperText={errors.estimate?.message}
              onBlur={(e) => {
                setValue("estimate", Number(e.target.value).toFixed(2));
              }}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Advance"
              InputLabelProps={{ shrink: true }}
              {...register("advance", {})}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 15),
                onFocus: handleFocus,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyRupeeIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.advance}
              helperText={errors.advance?.message}
              onBlur={(e) => {
                setValue("advance", Number(e.target.value).toFixed(2));
              }}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              label="Password"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 20),
              }}
              {...register("paswd", {})}
              error={!!errors.paswd}
              helperText={errors.paswd?.message}
              disabled={getValues("isstatus") == 2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Remark/Narration"
              InputLabelProps={{ shrink: true }}
              {...register("remark", {})}
              error={!!errors.remark}
              helperText={errors.remark?.message}
              disabled={getValues("isstatus") == "2"}
            />
          </Grid>
          <Grid item xs={12}>
            {photos?.length > 4 && (
              <Typography color="error" variant="subtitle2">
                You can only upload a maximum of 4 images{" "}
              </Typography>
            )}
            <NewImagesUpload
              files={photos}
              setFiles={setPhotos}
              editImages={editImages}
              setEditImages={setEditImages}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default NewTask;
