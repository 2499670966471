import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData, setRetAnswer } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD, handleGetLocalStorage } from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";
import { handleNewTaskDrawer } from "./menuReducer";
  
  

const taskReducer = createSlice({
    name: 'task',
    initialState: {
        returnEqup:{
          retsim:0,
          retcover:0,
          retmc:0,
          retsimtray:0,
        },                
        imgList:[],
        taskCount:[],
        taskList:[],
        taskLists:[],
        pendingDaysCall:false,
        taskSearchInput:"", 
        taskSearchInputClick:false,
        taskWhere:{},
    },

    reducers: {           
         setReturnEqup(state, action) {
            state.returnEqup = action.payload;
        },   
        setTaskCount(state,action){
          state.taskCount=action.payload;
        },
        setTaskList(state,action){
          state.taskList=action.payload;
        },
        setTaskLists(state,action){
          state.taskLists=action.payload;
        },
        setPendingDaysCall(state,action){
          state.pendingDaysCall=action.payload;
        },
        setTaskSearchInput(state,action){
          state.taskSearchInput=action.payload;
        },
        setTaskSearchInputClick(state,action){
          state.taskSearchInputClick=action.payload;
        },
        setTaskWhere(state,action){
          state.taskWhere=action.payload;
        }          

    }
});

export const { setReturnEqup,setTaskCount,setTaskList,setPendingDaysCall,setTaskLists,
  setTaskSearchInput,setTaskSearchInputClick,setTaskWhere} = taskReducer.actions;
export default taskReducer.reducer;

export const handleTaskSearchInput = (data) => {
  return async function actionData(dispatch,getState) {      
      dispatch(setTaskSearchInput(data));     
  }
};

export const returnEquipmentSet = (e,setValue) => {
    return function actionData(dispatch,getState) {
      const {returnEqup}=getState().task;
      let data={};
       if(e.target.checked===true){
        setValue(e.target.name,1);
        data={...returnEqup,[e.target.name]:1};}
      else{setValue(e.target.name,0);
        data={...returnEqup,[e.target.name]:0};}
       dispatch(setReturnEqup(data));
    };
  };

export const saveTask = (data,whrData={},reset,navigate) => {   
  return async function actionData(dispatch, getState) {
      let url="task";
      if (parseInt(data?.id) > 0) {
        url="taskupdate"
      }
      let res = await callAjax(url, data);
      if (res.data > 0) {
        dispatch(
          setAlertData({
            type: "success",
            msg: "Task saved successfully",
            code: 200,
          })
        );
        if(window.location.pathname!=="/task" && url!=="taskupdate"){
          dispatch(handleNewTaskDrawer());
          dispatch(getTaskCount({},false));               
          dispatch(getTaskListMain(whrData,false,true));
        }  
        if(url==="taskupdate"){
          navigate('/home');
        }
        dispatch(setTaskList([]))
        reset();
      } else {
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
      }
    };
};

export const saveCompletedTask = (data) => {   
  return async function actionData(dispatch, getState) {
      const {taskLists}=getState().task;
      let url="taskwoimgupdate";
      let res = await callAjax(url, data);      
      if (res.data > 0) {
        dispatch(
          setAlertData({
            type: "success",
            msg: "Task saved successfully",
            code: 200,
          })
        );

        // const filterData=taskLists.filter(item => item.id !== data?.id);
        const filterData = taskLists.map(item => {
          if (item.id === data.id) {
              // Update the item with new key-value pairs              
              return { ...item, ...data };
          }
          // Return the item unchanged if the id does not match
          return item;
      });        
        dispatch(setTaskLists(filterData))
        dispatch(getTaskCount({},false));        
        
      } else {
        dispatch(
          setAlertData({
            type: "error",
            msg: "Something went wrong!Please try again",
            code: 100,
          })
        );
        
      }
      dispatch(setRetAnswer(false));
    };
};

  export const deleteTask = (data,whrData={}) => {   
    return async function actionData(dispatch, getState) {
        const {taskLists}=getState().task;
        let url="taskdelete";       
        let res = await callAjax(url, data);
        dispatch(setRetAnswer(false));
        if (res.data > 0) {
          const updatedItems = taskLists.filter(item => item.id !== data?.id);
          dispatch(
            setAlertData({
              type: "success",
              msg: "Task deleted successfully",
              code: 200,
            })
            );        
            dispatch(getTaskCount(),false); 
            dispatch(setTaskLists(updatedItems));

        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Something went wrong!Please try again 12",
              code: 100,
            })
          );
        }
        dispatch(setRetAnswer(false));
      };
    };

  export const getTaskList = (data,bal=true) => {
    let isError = 0;
    return async function actionData(dispatch) {
      let url="gettask";     
      data={...data, 
        cmpid:handleGetLocalStorage("cmpid")||0}
      let res = await callAjax(url, data,bal);
      dispatch(setTaskList(res?.data));      
    };
  };

  export const getTaskListMain = (data,bal=true,isNew=false) => {
     return async function actionData(dispatch,getState) {
      let url="gettask";     
      data={...data, 
        cmpid:handleGetLocalStorage("cmpid")||0}        
      let res = await callAjax(url, data, bal);

      const { taskLists,taskSearchInputClick,taskWhere } = getState().task;
        
      //  const newTaskLists = [...taskLists, ...res?.data];
      //  if(taskSearchInputClick){        
      //    dispatch(setTaskLists(res?.data));   
      //  }else if(taskWhere?.isfilter===true){        
      //   dispatch(setTaskLists(res?.data));   
      //  }else if(isNew==true){        
      //   dispatch(setTaskLists(res?.data));   
      //  }
      //  else{        
      //   dispatch(setTaskLists(newTaskLists));   
      //  }
       dispatch(setTaskLists(res?.data));  
      //  dispatch(setTaskSearchInput(""));  
       dispatch(setTaskSearchInputClick(false));
       dispatch(setTaskWhere({}));
    };
  };

  export const getTaskCount = (data,bal=true) => {
    let isError = 0;
    return async function actionData(dispatch, getState) {
      let url="gettaskcount";     
      data={
        cmpid:handleGetLocalStorage("cmpid") || 0
       }
      let res = await callAjax(url, data,bal);
      dispatch(setTaskCount(res?.data));      
    };
  };

  export const getCntByIsstatus = (data,isstatus) => {
    return  function actionData(dispatch, getState) {
    const item = data.find((item) => item.isstatus === isstatus);
    return item ? item.cnt : 0;
    }
  };
 
  export const updatePendingTask = (data,bal=true) => {    
    return async function actionData(dispatch) {
      let url="update_pending_task";     
      data={...data, cmpid:handleGetLocalStorage("cmpid")||0}
       let res = await callAjax(url,data,bal);
     };
  };

  export const handlePendingDaysCall = () => {    
    return async function actionData(dispatch,getState) {
      const {pendingDaysCall}=getState().task;
      dispatch(setPendingDaysCall(!pendingDaysCall));
     };
  };

  export const handleTaskWhere = (data) => {    
    return async function actionData(dispatch,getState) {
      const {taskWhere}=getState().task;
      dispatch(setTaskWhere({...taskWhere,...data}));
     };
  };

  export const deleteTaskImage = (data,whrData={}) => {   
    return async function actionData(dispatch, getState) {
        const {taskList}=getState().task;
        let url="task_del_image";       
        let res = await callAjax(url, data);
        if (res.data > 0) {
          // const updatedItems = taskList.filter(item => item.id !== data?.id);
          // dispatch(setTaskList(updatedItems));
          dispatch(
            setAlertData({
              type: "success",
              msg: "Image deleted successfully",
              code: 200,
            })
          );        

        } else {
          dispatch(
            setAlertData({
              type: "error",
              msg: "Something went wrong!Please try again",
              code: 100,
            })
          );
        }
      };
    };
