export const getCurrentWeekDate = (date) => {
  // Convert the date to a UTC date object
  date = new Date(date + "Z");

  // Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  let dayOfWeek = date.getUTCDay();

  // Calculate the start of the week (Monday)
  let startOfWeek = new Date(date);
  startOfWeek.setUTCDate(date.getUTCDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  startOfWeek.setUTCHours(0, 0, 0, 0); // Set to the beginning of the day

  // Calculate the end of the week (Sunday)
  let endOfWeek = new Date(startOfWeek);
  endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6);
  endOfWeek.setUTCHours(23, 59, 59, 999); // Set to the end of the day

  // Format the start and end of the week
  const format = (date) => {
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    let day = String(date.getUTCDate()).padStart(2, "0");
    let hours = String(date.getUTCHours()).padStart(2, "0");
    let minutes = String(date.getUTCMinutes()).padStart(2, "0");
    let seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return {
    start: format(startOfWeek),
    end: format(endOfWeek),
  };
};
 
export const getCurrentMonthDate = (date) => {
  // Convert the date to a UTC date object
  date = new Date(date + "Z");

  // Calculate the start of the month
  let startOfMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
  startOfMonth.setUTCHours(0, 0, 0, 0); // Set to the beginning of the day

  // Calculate the end of the month
  let endOfMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));
  endOfMonth.setUTCHours(23, 59, 59, 999); // Set to the end of the day

  // Format the start and end of the month
  const format = (date) => {
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    let day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return {
    start: format(startOfMonth),
    end: format(endOfMonth),
  };
};

 
export const getNextMonthDate = (date) => {
  // Convert the date to a UTC date object
  date = new Date(date + "Z");

  // Calculate the start of the next month
  let startOfNextMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1));
  startOfNextMonth.setUTCHours(0, 0, 0, 0); // Set to the beginning of the day

  // Calculate the end of the next month
  let endOfNextMonth = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 2, 0));
  endOfNextMonth.setUTCHours(23, 59, 59, 999); // Set to the end of the day

  // Format the start and end of the next month
  const format = (date) => {
    let year = date.getUTCFullYear();
    let month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    let day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return {
    start: format(startOfNextMonth),
    end: format(endOfNextMonth),
  };
};

 
